import React from "react";
import RestriedImg from "../../../assets/images/Restricted_icon.svg";
import { withTranslation } from "react-i18next";

const AccessDenied = (props) => {
  return (
    <>
      <div className="access-deniedpage">
        <div className="access-inner-page">
          <div className="heading">{props.t('information')}</div>
          <div className="info-line"></div>
          <div className="access-info-box">
            <img src={RestriedImg} />
            <p>{props.t('user_is_blocked')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AccessDenied);
