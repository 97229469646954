import React, { useEffect, useState } from "react";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";
import { getJackpotTypes } from "./JackpotTypes";
const BolaTangakasWinner = (props) => {
  const { gameCode } = props;
  const [royalflushWins, setRoyalflushWins] = useState("");
  const [fiveKindWins, setFiveKindWins] = useState("");
  const [straightFlushWins, setStraightFlushWins] = useState("");
  const [fourOfKindWins, setFourOfKindWins] = useState("");
  const [jackpot, setJackpot] = useState("");
  const [topLatestJacpotWins, setTopLatestJacpotWins] = useState("");
  const [jackpotTypes, setJackpotTypes] = useState({});

  useEffect(() => {
    const types = getJackpotTypes(gameCode);
    setJackpotTypes(types);
  }, [gameCode]);

  // API for Bola Tangkas tables
  const fetchBolaTangkasData = () => {
    const reqData = {
      gamecode: gameCode,
      type: "list_winner_jackpot",
    };
    makeAjax(url.CARD_GAME_API.jackpot, reqData).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      console.log("response.data ===>", response.data);
      
      setRoyalflushWins(response.data.royalflush);
      setFiveKindWins(response.data.fiveofaKind);
      setStraightFlushWins(response.data.straightFlush);
      setFourOfKindWins(response.data.fourofaKind);
      setJackpot(response.data.topJackpot);
      setTopLatestJacpotWins(response.data.lastJackpot);
    });
  };

  useEffect(() => {
    fetchBolaTangkasData();
  }, []);
  
  const renderJackpotType = (prize) => {
    return jackpotTypes[prize] || "-";
  };

  return (
    <div className="jackpot_winner_table">
      {/* TOP ROYAL FLUSH WIN */}
      <h5 className="table_title">TOP ROYAL FLUSH WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {royalflushWins.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              royalflushWins.map((royalFlush, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{royalFlush.datetime}</td>
                  <td>{royalFlush.user_name.replace("TTJ_",'')}</td>
                  <td>{(royalFlush.meja)}</td>
                  <td>{(royalFlush.prize)}</td>            
                  <td>{formatPricewithdecimal(royalFlush.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* TOP FIVE OF KIND WIN */}
      <h5 className="table_title">TOP FIVE OF KIND WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {fiveKindWins.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              fiveKindWins.map((fiveKind, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{fiveKind.datetime}</td>
                  <td>{fiveKind.user_name.replace("TTJ_",'')}</td>
                  <td>{(fiveKind.meja)}</td>
                  <td>{(fiveKind.prize)}</td>            
                  <td>{formatPricewithdecimal(fiveKind.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* TOP STRAIGHT FLUSH WIN */}
      <h5 className="table_title">TOP STRAIGHT FLUSH WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {straightFlushWins.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              straightFlushWins.map((straightFlush, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{straightFlush.datetime}</td>
                  <td>{straightFlush.user_name.replace("TTJ_",'')}</td>
                  <td>{(straightFlush.meja)}</td>
                  <td>{(straightFlush.prize)}</td>            
                  <td>{formatPricewithdecimal(straightFlush.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* TOP FOUR OF KIND WIN */}
      <h5 className="table_title">TOP FOUR OF KIND WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {fourOfKindWins.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              fourOfKindWins.map((fourKindWin, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{fourKindWin.datetime}</td>
                  <td>{fourKindWin.user_name.replace("TTJ_",'')}</td>
                  <td>{(fourKindWin.meja)}</td>
                  <td>{(fourKindWin.prize)}</td>            
                  <td>{formatPricewithdecimal(fourKindWin.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* TOP JACKPOT WIN */}
      <h5 className="table_title">TOP JACKPOT WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {jackpot.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              jackpot.map((jackpt, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{jackpt.datetime}</td>
                  <td>{jackpt.user_name.replace("TTJ_",'')}</td>
                  <td>{(jackpt.meja)}</td>
                  <td>{(jackpt.prize)}</td>            
                  <td>{formatPricewithdecimal(jackpt.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* TOP LATEST JACKPOT WIN */}
      <h5 className="table_title">TOP LATEST JACKPOT WIN</h5>
      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("date")}</th>
              <th>{props.t("tableplayer")}</th>
              <th>{props.t("tablename")}</th>
              <th>Jackpot</th>
              <th>{props.t("win")}</th>
            </tr>
          </thead>
          <tbody>
            {topLatestJacpotWins.length == 0 ? (
              <tr className="table_data_empty_row ">
                <td colSpan="6">{props.t("data_not_found")}</td>
              </tr>
            ) : (
              topLatestJacpotWins.map((latestJackpot, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{latestJackpot.datetime}</td>
                  <td>{latestJackpot.user_name.replace("TTJ_",'')}</td>
                  <td>{(latestJackpot.meja)}</td>
                  <td>{(latestJackpot.prize)}</td>            
                  <td>{formatPricewithdecimal(latestJackpot.win_jackpot)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withTranslation()(BolaTangakasWinner);
