import { useEffect, useState } from "react";

const Promotion = (props) => {
    const { id, promoImg, startDate, endDate, onclick, activeState } = props;
    return (
        <div className={`promotion ${activeState === id ? "active" : ""}`} id={`promo-${id}`} >
            <div className="promotion-banner-holder" >
                <img src={promoImg} alt="" />
                <button className="promotion-button" onClick={() => onclick(id)}>More Info</button>
            </div>
            <div className="promo-content-outer" style={{ maxHeight: activeState === id ? 1000 : 0 }}>
                <div className="promo-content">
                    {/* <div className="promotion_content_header">
                        <h4>Start date : {startDate}</h4>
                        <h4>END date : {endDate}</h4>
                    </div> */}
                    <div className="promotion-cnt">
                        <h2 className="promotion-heading">BONUS NEW YEAR TEXAS POKER</h2>
                        <h3 className="promotion-subheading">Kabar Gembira !!!</h3>
                        {/* <ol>
                            <li>Telah hadir <strong>BONUS NEW YEAR</strong> pada Permainan Texas Poker.</li>
                            <li>Promo Bonus New Year dimulai dari tanggal 01 Desember 2024 s.d 05 Januari 2025.</li>
                            <li>Semua member yang bermain akan memiliki kesempatan yang sama untuk mendapatkan bonus.</li>
                            <li>Tidak ada batasan Minimal / Maksimal turnover atau kekalahan dalam permainan.</li>
                        </ol> */}
                        <p>Telah hadir <strong>BONUS NEW YEAR</strong> pada Permainan Texas Poker.</p>
                        <p>Promo Bonus New Year dimulai dari tanggal 01 Desember 2024 s.d 05 Januari 2025.</p>
                        <p>Semua member yang bermain akan memiliki kesempatan yang sama untuk mendapatkan bonus.</p>
                        <p>Tidak ada batasan Minimal / Maksimal turnover atau kekalahan dalam permainan.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Promotion;