import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import BannerImg1 from "../../../assets/images/banner/Banner.jpeg";
import BannerImg2 from "../../../assets/images/banner/Banner_2.jpg";
import BannerImg3 from "../../../assets/images/banner/Banner_3.jpg";
import moBannerImg1 from "../../../assets/images/banner/banner.png";
import moBannerImg2 from "../../../assets/images/banner/mo-banner-2.jpg";
import moBannerImg3 from "../../../assets/images/banner/mo-banner-3.jpg";

const Carousel = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);

  const settings = {
    dots: true,
    fade: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  };

  const Banners = [
    {
      id: 3,
      imgSrc: BannerImg1,
      mobileimgSrc: moBannerImg1,
    },
    // {
    //   id: 1,
    //   imgSrc: BannerImg2,
    //   mobileimgSrc: moBannerImg2,
    // },
    // {
    //   id: 2,
    //   imgSrc: BannerImg3,
    //   mobileimgSrc: moBannerImg3,
    // },
  ];

  const handleBannerClick = () => {
    const clickedBanner = Banners[activeIndex];
    // console.log("Clicked ID:", clickedBanner.id);
    navigate(`/promotion?id=${clickedBanner.id}`);
  };


  return (
    <div className="mobile_carousel">
      <Slider {...settings} >
        {Banners.map((banner, index) => (
          <div key={banner.id} className="carousel-item" >
            <img
              src={banner.imgSrc}
              alt="Desktop Banner"
              className="desktop_banner_img"
              onClick={handleBannerClick}
            />
            <img
              src={banner.mobileimgSrc}
              alt="Mobile Banner"
              className="mobile_portrait_banner_img"
              onClick={handleBannerClick}
            />
          </div>
        ))}
      </Slider>

    </div>
  );
};

export default Carousel;
