import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {makeAjax,respStatus,showMessage,url} from "../../../helpers/global_helper";
import CloseIcon from "../../../assets/images/modal/close.svg";
import { formatPrice, formatPricewithdecimal } from "../../NumberFormat";
import { cardGameImages } from "./CardImages";
import { dominoDiceImage } from "./DominoImages";
import { BullBullGreenImages, BullBullWhiteImages } from "./DaduImages";

const DrawPopup = (props) => {
  const { status, bettype, date, gameId, limitId, period, transId, onClose } = props;
  const [transactionLoseData, setTransactionLoseData] = useState([]);
  const [gameName, setGameName] = useState("");
  const [gameID, setGameID] = useState("");
  // console.log("@@@@gameName",gameName,"@@@@gameID",gameID)
  const [baccaratData, setBaccaratData] = useState(null);
  const [rokakUrukData, setRokakUrukData] = useState(null);
  const [daduPoker, setDaduPoker] = useState(null);
  const [bullBullDice, setBullBullDice] = useState(null);
  const [gaple, setGaple] = useState(null);
  const [cardsTop, setCardsTop] = useState(null);
  const [cardsBottom, setCardsBottom] = useState(null);
  const [cardsLeft, setCardsLeft] = useState(null);
  const [cardsRight, setCardsRight] = useState(null);
  const [bullDiceRankingName, setBullDiceHandRankingName] = useState([]);
  const [bandardata, setBandarData] = useState(null);
  const [bandarCards, setBandarCards] = useState([]);
  const [tangkasClassicData, setTangkasClassicData] = useState([]);
  const [bolaTangkasData, setBolaTnagkasData] = useState([]);
  const renderTable =
    (transactionLoseData && transactionLoseData.length > 0) ||
    (tangkasClassicData && tangkasClassicData.length > 0) ||
    (bolaTangkasData && bolaTangkasData.length > 0) ||
    // (baccaratData && baccaratData.length > 0) ||
    (baccaratData && Object.keys(baccaratData).length > 0) ||
    (rokakUrukData && rokakUrukData.length > 0) ||
    (daduPoker && daduPoker.length > 0) ||
    (bullBullDice && bullBullDice.length > 0) ||
    (gaple && gaple.length > 0) ||
    (bandardata && bandardata.length > 0);

  // Fetch win/lose details from API
  const fetchTransactionWinLoseDetail = () => {
    let reqData = {
      act: status,
      bettype: bettype,
      date: date,
      game_id: gameId,
      limitid: limitId,
      period: parseInt(period),
      trans_id: transId,
    };

    makeAjax(url.PLAYER_API.getTransaction, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status !== respStatus.SUCCESS) {
          showMessage(response);
          return;
        }
        // Set data based on the game name
        setTransactionLoseData(response.data.results);
        setGameName(response.data.game.game_name);
        setGameID(response.data.game.game_id);
        switch (response.data.game.game_id) {
          // case "Bull Bull Dice": 114
          case 114:
            setBullBullDice(response.data.results);
            setBullDiceHandRankingName(response.data.handRankingName);
            break;
          // case "Dadu Poker": 118
          case 118:
            setDaduPoker(response.data);
            break;
          // case "Baccarat":115
          case 115:
            setBaccaratData(response.data);
            break;
          // case "Rokak Uruk":119
          case 119:
            setRokakUrukData(response.data);
            break;
          // case "Bandar QQ":116
          case 116:
            setBandarData(response.data.results);
            setBandarCards(response.data.cards);
            break;
          // case "Tangkas Classic":158
          case 158:
            setTangkasClassicData(response.data ? [response.data] : []);
            break;
          // case "Bola Tangkas":108
          case 108:
            setBolaTnagkasData(response.data ? [response.data] : []);
            break;
          // case "Gaple":117
          case 117:
            setGaple(response.data);
            setCardsTop(response.data.cardsTop);
            setCardsBottom(response.data.cardsBottom);
            setCardsLeft(response.data.cardsLeft);
            setCardsRight(response.data.cardsRight);
            break;
          default:
            break;
        }
      }
    );
  };

  useEffect(() => {
    fetchTransactionWinLoseDetail();
  }, []);

  // Function to generate Bandar QQ/RokakUruk/jisong domino images
  const generateDominoImages = (cardString) => {
    const cardValues = cardString.split(",").slice(0);
    return cardValues.map((card, index) => (
      <img
        key={index}
        src={dominoDiceImage[card]}
        style={{ width: "25px", marginLeft: "2px" }}
        alt={`card-${card}`}
      />
    ));
  };
  // Function to generate Ceme Fighter/Game Ceme/ Domino QQ images
  const generateCemeDominoImages = (arrCard) => {
    return arrCard.map((card, index) => (
      <img
        key={index}
        src={dominoDiceImage[card]}
        style={{ width: "25px", marginLeft: "2px" }}
        alt={`card-${card}`}
      />
    ));
  };
  // Function to generate dice images for Dadu Poker
  const generateDaduPokerImages = (diceString) => {
    const diceValues = diceString.split("").map(Number);
    return diceValues.map((dice, index) => (
      <img
        key={index}
        src={BullBullWhiteImages[dice]}
        style={{ width: "30px", marginLeft: "2px" }}
        alt={`dice-${dice}`}
      />
    ));
  };
  // Function to generate dice images for Bull Bull Dice
  const generateBullDiceImages = (diceString) => {
    const diceValues = diceString.split(",").map((val) => val.trim());
    return diceValues.map((dice, index) => {
      const diceValue = Number(dice[0]); // First digit is the dice value (1-6)
      const diceColor = dice[1] === "1" ? "green" : "white"; // Second digit determines color

      const diceImage =
        diceColor === "green"
          ? BullBullGreenImages[diceValue]
          : BullBullWhiteImages[diceValue];

      return (
        <img
          key={index}
          src={diceImage}
          style={{ width: "25px", marginLeft: "2px" }}
          alt={`dice-${diceValue}-${diceColor}`}
        />
      );
    });
  };
  // Helper function to get hand ranking and kicker Bull Bull dice
  const getHandRankingAndKicker = (card) => {
    const explodedCard = card.split(":");
    const handRankingIndex = explodedCard[1].split(",")[0];
    const kickerValue = explodedCard[1].split(",")[1];
    const handRanking = bullDiceRankingName[handRankingIndex] || "No Data";
    return `${handRanking} Kicker ${kickerValue || "No Data"}`;
  };
  // Function to generate game card images on value basis
  const generateGameCardImages = (arrCard) => {
    return Object.values(arrCard).map((cardSymbol, index) => (
      <img
        key={index}
        src={cardGameImages[cardSymbol]}
        style={{ width: "30px", marginLeft: "2px" }}
        alt={`card-${cardSymbol}`}
      />
    ));
  };
  // Function to generate Tangkas Classic card images
  const generateTangkasCardImages = (arrCard) => {
    if (!arrCard || arrCard.length === 0) return null;
    return arrCard.map((cardSymbol, index) => (
      <img
        key={index}
        src={cardGameImages[cardSymbol] || "default-image.png"}
        style={{ width: "30px", marginLeft: "2px" }}
        alt={`card-${cardSymbol}`}
      />
    ));
  };
  // Function to generate Gaple Round Result card images
  const generateGapleImages = (cardsArray, orientation = "normal") => {
    return cardsArray.map((card, index) => {
      const cardId = card[4];
      const rotation = card[3] || "0";
      const isPortrait = card[2] === "portrait";
      let style = {
        display: "inline-block",
        transform: `rotate(${rotation}deg)`,
        marginRight: isPortrait ? "8px" : "14px",
      };
      return (
        <div key={index} style={style}>
          <img
            src={dominoDiceImage[cardId]}
            style={{ width: "30px" }}
            alt={`card-${cardId}`}
          />
        </div>
      );
    });
  };
  // Function to generate Gaple card images
  const generateGapleCardImages = (cardsArray) => {
    return cardsArray.map((card, index) => {
      const cardDetails = card.split("#");
      const cardId = cardDetails[0];
      const rotation = cardDetails[3] || "0";

      return (
        <div
          key={index}
          style={{
            display: "inline-block",
            transform: `rotate(${rotation}deg)`,
          }}
        >
          <img
            src={dominoDiceImage[cardId]}
            style={{ width: "30px" }}
            alt={`card-${cardId}`}
          />
        </div>
      );
    });
  };
  const groupByNickname = (data) => {
    if (!data || !Array.isArray(data)) return {};
    return data.reduce((acc, item) => {
      (acc[item.nickname] = acc[item.nickname] || []).push(item);
      return acc;
    }, {});
  };

  return (
    <>
      <div className="modal_overlay" onClick={onClose}></div>
      <div className="modal_popup">
        <div className="modal_popup_header">
          {renderTable && (
            <>
              {props.t("draw")} - {gameName}
            </>
          )}
          <div className="header_bottom_border"></div>
          <button className="modal_popup_close" onClick={onClose}>
            <img src={CloseIcon} alt="close" />
          </button>
        </div>
        <div className="tables_container">
          {/* gameName === Jisong */}
          {renderTable && gameID === 120 && (
            <>
              <div className="tab_content">
                <p>Round 1</p>
                <div className="table_main">
                  <table className="dataTableMenuBar" border="0">
                    <thead>
                      <tr>
                        <th>{props.t("nickname")}</th>
                        <th>{props.t("card")}</th>
                        <th>{props.t("status")}</th>
                        <th>{props.t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionLoseData.length === 0 ? (
                        <tr className="table_data_empty_row ">
                          <td colSpan="4">{props.t("data_not_found")}</td>
                        </tr>
                      ) : (
                        transactionLoseData
                          .filter((item) => item.bet_type === 1)
                          .map((value, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "first_bg" : "second_bg"
                              }
                            >
                              <td>{value.nickname}</td>
                              <td>
                                {generateDominoImages(value.card.split(",")[0])}
                              </td>
                              <td>
                                {value.winlose < 0
                                  ? props.t("lose")
                                  : props.t("win")}
                              </td>
                              <td>{formatPrice(value.winlose)}</td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab_content">
                <p>Round 2</p>
                <div className="table_main">
                  <table className="dataTableMenuBar" border="0">
                    <thead>
                      <tr>
                        <th>{props.t("nickname")}</th>
                        <th>{props.t("card")}</th>
                        <th>{props.t("status")}</th>
                        <th>{props.t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionLoseData.length === 0 ? (
                        <tr className="table_data_empty_row ">
                          <td colSpan="4">{props.t("data_not_found")}</td>
                        </tr>
                      ) : (
                        transactionLoseData
                          .filter((item) => item.bet_type === 2)
                          .map((value, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "first_bg" : "second_bg"
                              }
                            >
                              <td>{value.nickname}</td>
                              <td>
                                {generateDominoImages(
                                  value.card.split(",").slice(0, 2).join(",")
                                )}
                              </td>
                              <td>
                                {value.winlose < 0
                                  ? props.t("lose")
                                  : props.t("win")}
                              </td>
                              <td>{formatPrice(value.winlose)}</td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          {/* gameName === "Baccarat" */}
          {renderTable && gameID === 115 && baccaratData && (
            <div className="tab_content">
              <div className="table_main">
                <div className="Player_cards">
                  <p>Player Cards : {baccaratData.cards.value.player}</p>
                  <div className="cards-inline">
                    {generateGameCardImages(baccaratData.cards.arrCard.player)}
                  </div>
                  <p>Banker Cards : {baccaratData.cards.value.banker}</p>
                  <div className="cards-inline">
                    {generateGameCardImages(baccaratData.cards.arrCard.banker)}
                  </div>

                  <h6>{baccaratData.cards.statusWin}</h6>
                </div>
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>{props.t("seats")}</th>
                      <th>{props.t("nickname")}</th>
                      <th>{props.t("bet_details")}</th>
                      <th>{props.t("status")}</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(baccaratData.results).map((key, index) => {
                      const seats = baccaratData.results[key][0]?.seat;
                      const details = baccaratData.results[key][0]?.details;
                      const winlose = baccaratData.results[key][0]?.winlose;

                      return (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "first_bg" : "second_bg"}
                        >
                          <td>{seats}</td>
                          <td>{key}</td>
                          <td>{details}</td>
                          <td>
                            {winlose < 0 ? props.t("lose") : props.t("win")}
                          </td>
                          <td>{winlose && formatPrice(winlose)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Rokak Uruk" */}
          {renderTable && gameID === 119 && rokakUrukData && (
            <div className="tab_content">
              <div className="table_main">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>Pot</th>
                      <th>{props.t("chair")}</th>
                      <th>{props.t("nickname")}</th>
                      <th>Kartu</th>
                      <th>{props.t("status")}</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(rokakUrukData.potBetTypeArr).map((pot) => {
                      const potResults = rokakUrukData.results
                        .filter((result) =>
                          rokakUrukData.potBetTypeArr[pot].includes(
                            result.bet_type
                          )
                        )
                        .sort((a, b) => a.sit - b.sit);

                      return potResults.map((result, index) => (
                        <tr
                          key={`${pot}-${index}`}
                          className={index % 2 === 0 ? "first_bg" : "second_bg"}
                        >
                          {index === 0 && (
                            <td rowSpan={potResults.length}>{pot}</td>
                          )}
                          <td>{result.sit === 0 ? "Dealer" : "Player"}</td>
                          <td>{result.nickname}</td>
                          {result.sit === 0 ? (
                            <td>
                              {generateDominoImages(rokakUrukData.cards[0])}
                            </td>
                          ) : (
                            index === 1 && (
                              <td
                                rowSpan={potResults.length - 1}
                                style={{ backgroundColor: "#310000" }}
                              >
                                {generateDominoImages(rokakUrukData.cards[pot])}
                              </td>
                            )
                          )}
                          <td>
                            {result.winlose < 0
                              ? props.t("lose")
                              : props.t("win")}
                          </td>
                          <td>{formatPrice(result.winlose)}</td>
                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Dadu Poker" */}
          {renderTable && gameID === 118 && daduPoker && (
            <div className="tab_content">
              <div className="table_main">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>{props.t("nickname")}</th>
                      <th>{props.t("status")}</th>
                      <th>{props.t("cards")}</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {daduPoker.cards.map((card, roundIndex) =>
                      card.sit.map((_, playerIndex) => (
                        <tr key={`${roundIndex}-${playerIndex}`}>
                          <td className="border-none">
                            {daduPoker.results[playerIndex].nickname}
                          </td>
                          <td className="border-none">
                            {card.winlose[playerIndex] < 0
                              ? props.t("lose")
                              : props.t("win")}
                            -
                            {props.t(
                              daduPoker.handRankingName[
                                card.handRanking[playerIndex]
                              ]
                            )}
                          </td>
                          <td className="border-none">
                            {generateDaduPokerImages(card.dice[playerIndex])}
                          </td>
                          <td className="border-none">
                            {formatPrice(card.winlose[playerIndex])}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Gaple" */}
          {renderTable && gameID === 117 && gaple && (
            <div className="tab_content">
              <div className="table_main">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>{props.t("nickname")}</th>
                      <th>{props.t("status")}</th>
                      <th>{props.t("cards")}</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <h1>Round Result</h1>
                        {cardsTop?.length ||
                        cardsLeft?.length ||
                        cardsRight?.length ||
                        cardsBottom?.length ? (
                          <>
                            {cardsTop && generateGapleImages(cardsTop, "top")}
                            {cardsLeft &&
                              generateGapleImages(cardsLeft, "left")}
                            {cardsRight &&
                              generateGapleImages(cardsRight, "right")}
                            {cardsBottom &&
                              generateGapleImages(cardsBottom, "bottom")}
                          </>
                        ) : (
                          <p>No Data Found</p>
                        )}
                      </td>
                    </tr>

                    {gaple.results.length === 0 ? (
                      <tr className="table_data_empty_row">
                        <td colSpan="4">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      gaple.results.map((value, index) => {
                        const cardsArray = value.card.split("@")[0].split(",");
                        return (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "first_bg" : "second_bg"
                            }
                          >
                            <td>{value.nickname}</td>
                            <td>
                              {parseFloat(value.winlose) < 0
                                ? props.t("lose")
                                : props.t("win")}
                            </td>
                            <td>{generateGapleCardImages(cardsArray)}</td>
                            <td>{formatPrice(value.winlose)}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Bull Bull Dice" */}
          {renderTable && gameID === 114 && bullBullDice && (
            <div className="tab_content">
              <div className="table_main">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>{props.t("nickname")}</th>
                      <th>{props.t("status")}</th>
                      <th>Dadu</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bullBullDice.length === 0 ? (
                      <tr className="table_data_empty_row">
                        <td colSpan="4">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      bullBullDice.map((value, index) => {
                        // Process card to get hand ranking and kicker
                        const [card] = value.card.split(":");
                        return (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "first_bg" : "second_bg"
                            }
                          >
                            {/* nickname */}
                            <td>{value.nickname}</td>
                            {/* status */}
                            <td>
                              {value.winlose < 0
                                ? props.t("Lose")
                                : props.t("Win")}
                              {` - ${getHandRankingAndKicker(value.card)}`}
                            </td>
                            {/* dadu */}
                            <td>{generateBullDiceImages(card)}</td>
                            {/* total */}
                            <td>{formatPricewithdecimal(value.winlose)}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Bandar QQ" */}
          {renderTable && gameID === 116 && bandardata && (
            <div className="bandartab_content">
              <div className="table_main">
                <div className="domino_cards">
                  <div className="red_domino">
                    <p>Red</p>
                    {/* Generate Red Domino Cards */}
                    {generateDominoImages(bandarCards[0])}
                    {/* Conditionally show Win/Lose based on bandarCards[2] */}
                    {bandarCards[2] !== "TIE" && (
                      <p>
                        {bandarCards[2] === "RED WIN"
                          ? props.t("win")
                          : props.t("lose")}
                      </p>
                    )}
                  </div>
                  <div className="blue_domino">
                    <p>Blue</p>
                    {/* Generate Blue Domino Cards */}
                    {generateDominoImages(bandarCards[1])}
                    {/* Conditionally show Win/Lose based on bandarCards[2] */}
                    {bandarCards[2] !== "TIE" && (
                      <p>
                        {bandarCards[2] === "BLUE WIN"
                          ? props.t("win")
                          : props.t("lose")}
                      </p>
                    )}
                  </div>
                  {/* Display Tie condition */}
                  {bandarCards[2] === "TIE" && (
                    <h6 style={{ color: "green" }}>Tie</h6>
                  )}
                </div>
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th>{props.t("seats")}</th>
                      <th>{props.t("nickname")}</th>
                      <th>Rincian Taruhan</th>
                      <th>{props.t("status")}</th>
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bandardata && bandardata.length === 0 ? (
                      <tr className="table_data_empty_row">
                        <td colSpan="5">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      Object.entries(groupByNickname(bandardata)).map(
                        ([nickname, data], index) => (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "first_bg" : "second_bg"
                            }
                          >
                            <td>{data[0].sit === 0 ? "Dealer" : "Player"}</td>
                            <td>{nickname}</td>
                            <td>
                              {data.map((result, i) => (
                                <p key={i}>
                                  {result.bet_type === 0 &&
                                    `Pot Merah = ${formatPricewithdecimal(
                                      result.winlose
                                    )}`}
                                  {result.bet_type === 1 &&
                                    `Pot Hijau = ${formatPricewithdecimal(
                                      result.winlose
                                    )}`}
                                  {result.bet_type === 2 &&
                                    `Pot Biru = ${formatPricewithdecimal(
                                      result.winlose
                                    )}`}
                                </p>
                              ))}
                            </td>
                            <td>
                              {data[0].winlose < 0
                                ? props.t("lose")
                                : props.t("win")}
                            </td>
                            <td>
                              {formatPricewithdecimal(
                                data.reduce(
                                  (sum, item) => sum + parseFloat(item.winlose),
                                  0
                                )
                              )}
                            </td>
                          </tr>
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {/* gameName === "Tangkas Classic" */}
          {renderTable &&
            gameID === 158 &&
            tangkasClassicData &&
            Array.isArray(tangkasClassicData) && (
              <div className="tab_content">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{props.t("cards")}</th>
                      <th>{props.t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tangkasClassicData.length === 0 ? (
                      <tr className="table_data_empty_row">
                        <td colSpan="3">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      tangkasClassicData.map((value, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "first_bg" : "second_bg"}
                        >
                          <td>Hand Card</td>
                          <td class="cards-inline">
                            {value?.handCard?.arrCard &&
                            Array.isArray(value.handCard.arrCard)
                              ? generateTangkasCardImages(
                                  value.handCard.arrCard
                                )
                              : props.t("cards_not_found")}
                          </td>
                          <td>
                            {value?.status || props.t("status_not_found")}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          {/*  gameName === "Bola Tangkas" */}
          {renderTable &&
            gameID === 108 &&
            bolaTangkasData &&
            Array.isArray(bolaTangkasData) && (
              <div className="tab_content">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{props.t("cards")}</th>
                      <th>{props.t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bolaTangkasData.length === 0 ? (
                      <tr className="table_data_empty_row">
                        <td colSpan="3">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      bolaTangkasData.map((value, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "first_bg" : "second_bg"}
                        >
                          <td>Hand Card</td>
                          <td className="cards-inline">
                            {value?.handCard?.arrCard &&
                            Array.isArray(value.handCard.arrCard)
                              ? generateTangkasCardImages(
                                  value.handCard.arrCard
                                )
                              : props.t("cards_not_found")}
                          </td>
                          <td>
                            {value?.status || props.t("status_not_found")}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}

          {renderTable &&
          //  gameName !== "Jisong" &&
          //  gameName !== "Baccarat" &&
          //  gameName !== "Rokak Uruk" &&
          //  gameName !== "Dadu Poker" &&
          //  gameName !== "Gaple" &&
          //  gameName !== "Bandar QQ" &&
          //  gameName !== "Tangkas Classic" &&
          //  gameName !== "Bola Tangkas" &&
          //  gameName !== "Bull Bull Dice" &&
            gameID !== 120 &&
            gameID !== 115 &&
            gameID !== 119 &&
            gameID !== 118 &&
            gameID !== 117 &&
            gameID !== 116 &&
            gameID !== 158 &&
            gameID !== 108 &&
            gameID !== 114 && (
              <div className="table_main">
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      {(
                        // gameName === "Black Jack - 106"
                        gameID === 106 ||
                        // gameName === "Ceme Fighter" -104
                        gameID === 104 ||
                        // gameName === "Game Ceme" -103
                        gameID === 103 ||
                        // gameName === "Poker Dealer"=102
                        gameID === 102 ||
                        //  gameName === "Three Kings" -111
                        gameID === 111 ||
                        //  gameName === "Joker Dealer" -112
                        gameID === 112) && (
                        <th>{props.t("seat")}</th>
                      )}
                      <th>{props.t("nickname")}</th>
                      {(
                        // gameName === "Texas Poker Classic" -151
                        gameID === 151 ||
                        //  gameName === "Omaha Poker" -110
                        gameID === 110 ||
                        // gameName === "Texas Poker" -101
                        gameID === 101 ||
                        //  gameName === "Big Two" - 107
                        gameID === 107) && <th>{props.t("status")}</th>}

                      <th>{props.t("card")}</th>
                      {(
                        // gameName === "Poker Dealer" -102
                        gameID === 102 ||
                        // gameName === "Super 10"-109
                        gameID === 109
                      ) && (
                        <th>{props.t("status")}</th>
                      )}

                      {
                      // gameName === "Three Kings" -111  
                      gameID === 111 && (
                        <th>{props.t("status")}</th>
                      )}
                      <th>{props.t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionLoseData.length === 0 ? (
                      <tr className="table_data_empty_row ">
                        <td colSpan="4">{props.t("data_not_found")}</td>
                      </tr>
                    ) : (
                      transactionLoseData.map((value, index) => (
                        <tr
                          key={index}
                          className={index % 2 === 0 ? "first_bg" : "second_bg"}
                        >
                          {/* ------------------seats-------------- */}
                          {(
                            // gameName === "Black Jack" ||
                            // gameName === "Ceme Fighter" ||
                            // gameName === "Game Ceme" ||
                            // gameName === "Joker Dealer"
                            gameID === 106 ||
                            gameID === 104 ||
                            gameID === 103 ||
                            gameID === 112
                          ) &&
                            value.status && <td>{value.status.status}</td>}

                          {
                            // gameName === "Three Kings"
                          gameID === 111 && (
                            <td>{value.sit === 0 ? "Dealer" : "Player"}</td>
                          )}
                          {
                          // gameName === "Poker Dealer" 
                          gameID === 102 && (
                            <td>{value.sit === 0 ? "Dealer" : "Player"}</td>
                          )}
                          {/* ---------------nickname---------------- */}
                          <td>{value.nickname}</td>

                          {(
                            // gameName === "Texas Poker Classic" ||
                            // gameName === "Omaha Poker" ||
                            // gameName === "Texas Poker"
                            gameID === 151 ||
                            gameID === 110 ||
                            gameID === 101
                          ) && (
                            <td>{value.status.status}</td>
                          )}

                          {
                          // gameName === "Big Two"
                          gameID ===107 && (
                            <td>{value.historyStatus}</td>
                          )}

                          {/* ----------------cards----------------- */}
                          {
                          // gameName === "Super 10"
                          gameID === 109 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.value.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Three Kings"
                          gameID === 111 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // ameName === "Texas Poker Classic"
                          gameID === 151 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Poker Dealer"
                          gameID === 102 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}

                          {
                          // gameName === "Big Two"
                          gameID === 107 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Texas Poker"
                          gameID === 101 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Ceme Fighter"
                          gameID === 104 && (
                            <td>
                              {generateCemeDominoImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Game Ceme"
                          gameID === 103 && (
                            <td>
                              {generateCemeDominoImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Domino QQ"
                          gameID === 105 && (
                            <td>
                              {generateCemeDominoImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Adu-Q"
                          gameID === 113 && (
                            <td>
                              {generateCemeDominoImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Omaha Poker"
                          gameID === 110 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {
                          // gameName === "Black Jack"
                          gameID === 106 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard[0])}
                            </td>
                          )}
                          {
                          // gameName === "Joker Dealer"
                          gameID === 112 && (
                            <td className="cards-inline">
                              {generateGameCardImages(value.status.arrCard)}
                            </td>
                          )}
                          {/* -----------------status------------------ */}
                          {
                          // gameName === "Poker Dealer"
                          gameID === 102 && (
                            <td>{value.status.status}</td>
                          )}
                          {
                          // gameName === "Three Kings"
                          gameID === 111 && (
                            <td>{value.status.status}</td>
                          )}

                          {
                          // gameName === "Super 10"
                          gameID === 109 && (
                            <td>{value.value.value}</td>
                          )}

                          {/* -----------------total-------------------- */}
                          <td>{formatPricewithdecimal(value.winlose)}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DrawPopup);
