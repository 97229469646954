import React from 'react';
import HKBlogo from "../../../../assets/images/Logo/HKB-logo.svg";
import { useNavigate } from 'react-router-dom';

const Logo = () => {
    const clientLogo = localStorage.getItem("loader") || HKBlogo;
    const navigate = useNavigate();
    
    const handleClickLogo = () => {
        navigate("/");
    };

    return (
        <div>
            <img
                src={clientLogo === "h" ? HKBlogo : clientLogo}
                alt="Client Logo"
                onClick={handleClickLogo}
                style={{ cursor: "pointer" }}
            />
        </div>
    );
};

export default Logo;
