import React, {useState, useEffect } from 'react'
import {
    makeAjax,
    respStatus,
    showMessage,
    url,
  } from "../../../helpers/global_helper";
import { withTranslation } from 'react-i18next';
import { formatPricewithdecimal } from '../../NumberFormat';
import { getJackpotTypes } from './JackpotTypes';
const CemeWinner = (props) => {
  const { gameCode } = props;
  const [sixDeiftyWins, setSixDeiftyWins] = useState("");
  const [twinCardWins, setTwinCardWins] = useState("");
  const [pureSmallWins, setPureSmallWins] = useState("");
  const [pureBiglWins, setPureBigWins] = useState("");
  const [topLatestJacpotWins, setTopLatestJacpotWins] = useState("");
  const [jackpotTypes, setJackpotTypes] = useState({});

  useEffect(() => {
    const types = getJackpotTypes(gameCode);
    setJackpotTypes(types);
  }, [gameCode]);

  // API for Ceme tables
  const fetchCemeData = () => {
    const reqData = {
      gamecode: gameCode,
      type: "list_winner_jackpot",
    };
    makeAjax(url.CARD_GAME_API.jackpot, reqData).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setSixDeiftyWins(response.data.sixDeity);
      setTwinCardWins(response.data.twinCard);
      setPureSmallWins(response.data.pureSmall);
      setPureBigWins(response.data.pureBig);
      setTopLatestJacpotWins(response.data.lastjackpot);
    });
  };

  useEffect(() => {
    fetchCemeData();
  }, []);
  const renderJackpotType = (prize) => {
    return jackpotTypes[prize] || "-";
  };

  return (
    <div className="jackpot_winner_table">
    {/* TOP SIX DEIFTY WIN */}
    <h5 className="table_title">TOP SIX DEITY WIN</h5>
    <div className="table_main">
      <table className="dataTableMenuBar" border="0">
        <thead>
          <tr>
            <th>{props.t("no.")}</th>
            <th>{props.t("date")}</th>
            <th>{props.t("tableplayer")}</th>
            <th>Jackpot</th>
            <th>{props.t("win")}</th>
          </tr>
        </thead>
        <tbody>
          {sixDeiftyWins.length == 0 ? (
            <tr className="table_data_empty_row ">
              <td colSpan="6">{props.t("data_not_found")}</td>
            </tr>
          ) : (
            sixDeiftyWins.map((sixDifty, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{sixDifty.datetime}</td>
                <td>{sixDifty.user_name.replace("TTJ_",'')}</td>
                <td>{renderJackpotType(sixDifty.prize)}</td>
                <td>{formatPricewithdecimal(sixDifty.win_jackpot)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>

    {/* TOP TWIN CARD WIN */}
    <h5 className="table_title">TOP TWIN CARD WIN</h5>
    <div className="table_main">
      <table className="dataTableMenuBar" border="0">
        <thead>
          <tr>
            <th>{props.t("no.")}</th>
            <th>{props.t("date")}</th>
            <th>{props.t("tableplayer")}</th>
            <th>Jackpot</th>
            <th>{props.t("win")}</th>
          </tr>
        </thead>
        <tbody>
          {twinCardWins.length == 0 ? (
            <tr className="table_data_empty_row ">
              <td colSpan="6">{props.t("data_not_found")}</td>
            </tr>
          ) : (
            twinCardWins.map((twinCard, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{twinCard.datetime}</td>
                <td>{twinCard.user_name.replace("TTJ_",'')}</td>
                <td>{renderJackpotType(twinCard.prize)}</td>
                <td>{formatPricewithdecimal(twinCard.win_jackpot)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>

    {/* TOP PURE SMALL WIN */}
    <h5 className="table_title">TOP PURE SMALL WIN</h5>
    <div className="table_main">
      <table className="dataTableMenuBar" border="0">
        <thead>
          <tr>
            <th>{props.t("no.")}</th>
            <th>{props.t("date")}</th>
            <th>{props.t("tableplayer")}</th>
            <th>Jackpot</th>
            <th>{props.t("win")}</th>
          </tr>
        </thead>
        <tbody>
          {pureSmallWins.length == 0 ? (
            <tr className="table_data_empty_row ">
              <td colSpan="6">{props.t("data_not_found")}</td>
            </tr>
          ) : (
            pureSmallWins.map((pureSmall, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{pureSmall.datetime}</td>
                <td>{pureSmall.user_name.replace("TTJ_",'')}</td>
                <td>{renderJackpotType(pureSmall.prize)}</td>
                <td>{formatPricewithdecimal(pureSmall.win_jackpot)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>

    {/* TOP PURE BIG WIN */}
    <h5 className="table_title">TOP PURE BIG WIN</h5>
    <div className="table_main">
      <table className="dataTableMenuBar" border="0">
        <thead>
          <tr>
            <th>{props.t("no.")}</th>
            <th>{props.t("date")}</th>
            <th>{props.t("tableplayer")}</th>
            <th>Jackpot</th>
            <th>{props.t("win")}</th>
          </tr>
        </thead>
        <tbody>
          {pureBiglWins.length == 0 ? (
            <tr className="table_data_empty_row ">
              <td colSpan="6">{props.t("data_not_found")}</td>
            </tr>
          ) : (
            pureBiglWins.map((pureBig, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{pureBig.datetime}</td>
                <td>{pureBig.user_name.replace("TTJ_",'')}</td>
                <td>{renderJackpotType(pureBig.prize)}</td>
                <td>{formatPricewithdecimal(pureBig.win_jackpot)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>

    {/* TOP LATEST JACKPOT WIN */}
    <h5 className="table_title">TOP LATEST JACKPOT WIN</h5>
    <div className="table_main">
      <table className="dataTableMenuBar" border="0">
        <thead>
          <tr>
            <th>{props.t("no.")}</th>
            <th>{props.t("date")}</th>
            <th>{props.t("tableplayer")}</th>
            <th>Jackpot</th>
            <th>{props.t("win")}</th>
          </tr>
        </thead>
        <tbody>
          {topLatestJacpotWins.length == 0 ? (
            <tr className="table_data_empty_row ">
              <td colSpan="6">{props.t("data_not_found")}</td>
            </tr>
          ) : (
            topLatestJacpotWins.map((latestJackpot, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{latestJackpot.datetime}</td>
                <td>{latestJackpot.user_name.replace("TTJ_",'')}</td>
                <td>{renderJackpotType(latestJackpot.prize)}</td>
                <td>{formatPricewithdecimal(latestJackpot.win_jackpot)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default withTranslation() (CemeWinner)

