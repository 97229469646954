import React, { useEffect, useState } from "react";
import Pagination from "../../Pagination/Pagination";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";
import { withTranslation } from "react-i18next";
import CalendarIcon from "../../../assets/images/icons/calender_icon.svg";
import BetPopup from "../InformationPopups/BetPopup";
import WinPopup from "../InformationPopups/WinPopup";
import { setTotalNoResult } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import LosePopup from "../InformationPopups/LosePopup";
import DrawPopup from "../InformationPopups/DrawPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Transaction = (props) => {
  const dispatch = useDispatch();
  const pageNumber = useSelector((state) => state.paginationData.pageNumber);
  const perPage = useSelector((state) => state.paginationData.dataInOnePage);
  const [startDate, setStartDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [gameName, setGameName] = useState([]);
  const [transectionData, setTransectionData] = useState([]);
  const [selectGameName, setSelectGameName] = useState("0");
  const [showBetPopup, setBetShowPopup] = useState(false);
  const [winPopup, setWinPopup] = useState(false);
  const [losePopup, setLosePopup] = useState(false);
  const [drawPopup, setDrawPopup] = useState(false);
  const [detailPopup, setDetailPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [betType, setBetType] = useState("");
  const [newDate, setNewDate] = useState("");
  const [gameId, setGameId] = useState("");
  const [game, setGame] = useState("");
  const [limitId, setLimitId] = useState("");
  const [period, setPeriod] = useState("");
  const [transectionId, setTransectionId] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log("gameName", gameName);
  const date = new Date(startDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  useEffect(() => {
    const formattedDate = new Date().toISOString().slice(0, 10);
    setCurrentDate(formattedDate);
  }, []);
  const CustomInput = ({ value, onClick }) => (
    <div style={{ position: "relative" }} className="custom_calendar">
      <input type="text" value={value} onClick={onClick} readOnly />
      <button
        onClick={onClick}
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          padding: "0",
          border: "none",
          background: "none",
          width: "20px",
        }}
      >
        <img src={CalendarIcon} alt="" />
      </button>
    </div>
  );

  // api for Game list
  const featchGameList = () => {
    makeAjax(url.CARD_GAME_API.getGameList).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      setGameName(response.data.gamelist);
    });
  };
  useEffect(() => {
    featchGameList();
  }, []);
  const handleGameSelectOptions = (e) => {
    const selectedValue = e.target.value;
    setSelectGameName(selectedValue);
  };
  // const handleBetOpen = (
  //   transId,
  //   status,
  //   bettype,
  //   gameId,
  //   game,
  //   date,
  //   limitId,
  //   period
  // ) => {
  //   setBetShowPopup(true);
  //   setTransectionId(transId);
  //   setStatus(status);
  //   setBetType(bettype);
  //   setGameId(gameId);
  //   setGame(game);
  //   setNewDate(date);
  //   setLimitId(limitId);
  //   setPeriod(period);
  // };
  const handleWinOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setWinPopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };

  const handleLoseOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setLosePopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };
  const handleDrowOpen = (
    transId,
    status,
    bettype,
    gameId,
    game,
    date,
    limitId,
    period
  ) => {
    setDrawPopup(true);
    setTransectionId(transId);
    setStatus(status);
    setBetType(bettype);
    setGameId(gameId);
    setGame(game);
    setNewDate(date);
    setLimitId(limitId);
    setPeriod(period);
  };

  const featchTransectionList = () => {
    setLoading(true);
    let reqData = {
      type: "history_results",
      page: pageNumber,
      date: formattedDate,
      game_id: parseInt(selectGameName),
      length: perPage,
    };
    makeAjax(url.PLAYER_API.listTransaction, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          showMessage(response);
          return;
        }
        setTransectionData(response.data.dataHistory);
        dispatch(setTotalNoResult(response.data.allHistory.total));
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    featchTransectionList();
    const container = document.querySelector(".table_container");
    if (container) {
      container.scrollTop = 0;
    }
  }, [perPage, pageNumber]);

  const handleSearchData = (e) => {
    e.preventDefault();
    const container = document.querySelector(".table_container");
    if (container) {
      container.scrollTop = 0;
    }
    props.handlePageNo();

    featchTransectionList();
  };

  const startIndex = (pageNumber - 1) * perPage;

  return (
    <div className="tab_content">
      <div className="filter_row_one">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="filter_row">
            <DatePicker
              // showIcon
              customInput={<CustomInput />}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="yyyy-MM-dd"
              maxDate={new Date()}
            />
            <select
              className="ui search dropdown"
              onChange={handleGameSelectOptions}
            >
              <option value="0">{props.t("all")}</option>
              {gameName.length > 0 &&
                gameName?.map(({ game_id, game_name }) => (
                  <option key={game_id} value={game_id}>
                    {game_name}
                  </option>
                ))}
            </select>
            <button className="btn" onClick={(e) => handleSearchData(e)}>
              {props.t("search")}
            </button>
          </div>
        </form>
      </div>

      <div className="table_main">
        <table className="dataTableMenuBar" border="0">
          <thead>
            <tr>
              <th>{props.t("no.")}</th>
              <th>{props.t("history")} ID</th>
              <th>{props.t("date")}</th>
              <th>{props.t("game")}</th>
              <th>{props.t("period")}</th>
              <th>{props.t("status")}</th>
              <th>{props.t("credit")}</th>
              <th>{props.t("debit")}</th>
              <th>{props.t("coin")}</th>
              <th>{props.t("balance")}</th>
              <th>{props.t("coin_reward")}</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              (transectionData.length == 0 ? (
                <tr className="table_data_empty_row">
                  <td colSpan="11">{props.t("data_not_found")}</td>
                </tr>
              ) : (
                transectionData.map((value, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "first_bg" : "second_bg"}
                  >
                    <td>{startIndex + index + 1}</td>
                    <td>{value.id}</td>
                    <td>{value.datetime}</td>
                    <td>{value.game_name}</td>
                    <td>{value.periode}</td>
                    <td>
                      {
                        // value.status === 21 || value.status === "Bet" ? (
                        //   <span
                        //     className="link"
                        //     onClick={() =>
                        //       handleBetOpen(
                        //         value.trans_id,
                        //         value.status,
                        //         value.bettype,
                        //         value.game_id,
                        //         value.game_name,
                        //         value.datetime,
                        //         value.limit_id,
                        //         value.period
                        //       )
                        //     }
                        //   >
                        //     {"Bet"}
                        //   </span>
                        // ) :
                        value.status === 22 || value.status === "Win" ? (
                          <span
                            className="link"
                            onClick={() =>
                              handleWinOpen(
                                value.trans_id,
                                value.status,
                                value.bettype,
                                value.game_id,
                                value.game_name,
                                value.datetime,
                                value.limit_id,
                                value.period
                              )
                            }
                          >
                            {props.t("win")}
                          </span>
                        ) : value.status === 23 || value.status === "Lose" ? (
                          <span
                            className="link"
                            onClick={() =>
                              handleLoseOpen(
                                value.trans_id,
                                value.status,
                                value.bettype,
                                value.game_id,
                                value.game_name,
                                value.datetime,
                                value.limit_id,
                                value.period
                              )
                            }
                          >
                             {props.t("lose")}
                          </span>
                        ) : value.status === 25 || value.status === "Draw" ? (
                          <span
                            className="link"
                            onClick={() =>
                              handleDrowOpen(
                                value.trans_id,
                                value.status,
                                value.bettype,
                                value.game_id,
                                value.game_name,
                                value.datetime,
                                value.limit_id,
                                value.period
                              )
                            }
                          >
                          {props.t("draw")}
                          </span>
                        ) : value.status === 30 || value.status === "Jackpot" ? (
                          props.t("win_regular_jackpot")
                        ) : value.status === 24 ? (
                          props.t("buy_gift")
                        ) : value.status === 26 ? (
                          "Topup"
                        ) : value.status === 27 ? (
                          "Buy In"
                        ) : value.status === 28 ? (
                          "Buy Out"
                        ) : value.status === 29 ? (
                          "Buy Mega Jackpot"
                        ) :
                        value.act === 7 ? (
                          props.t("expired_coin")
                        ): value.status === 36 ? (
                            "Cash Out Coins"
                          ) : (
                            value.status
                          )}
                    </td>

                    <td>{formatPricewithdecimal(value.credit)}</td>
                    <td>{formatPricewithdecimal(value.debit)}</td>
                    <td>{formatPricewithdecimal(value.coin)}</td>
                    <td>{formatPricewithdecimal(value.balance)}</td>
                    <td>{formatPricewithdecimal(value.reward_balance)}</td>
                  </tr>
                ))
              ))}
          </tbody>
        </table>
      </div>

      {
        /*---Bet popup ---*/
        showBetPopup && (
          <BetPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setBetShowPopup(false)}
          />
        )
      }
      {
        /*---Win popup ---*/
        winPopup && (
          <WinPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setWinPopup(false)}
          />
        )
      }

      {
        /*---Lose popup ---*/
        losePopup && (
          <>
            <LosePopup
              transId={transectionId}
              status={status}
              bettype={betType}
              gameId={gameId}
              date={newDate}
              limitId={limitId}
              period={period}
              onClose={() => setLosePopup(false)}
            />
          </>
        )
      }

      {
        /*---drawPopup popup ---*/
        drawPopup && (
          <DrawPopup
            transId={transectionId}
            status={status}
            bettype={betType}
            gameId={gameId}
            date={newDate}
            limitId={limitId}
            period={period}
            onClose={() => setDrawPopup(false)}
          />
        )
      }
    </div>
  );
};
export default withTranslation()(Transaction);
