import React, { useEffect, useState, useRef } from "react";
import InformationPopup from "./InformationPopup";
import CloseIcon from "../../../assets/images/modal/close.svg";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { useSelector } from "react-redux";

const AutoTable = (props) => {
  const { onClose, gameCode, selectedGameName, gameListImages, gameId, userLevel } = props;
  const [activeIndex, setActiveIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [autoTableLimit, setAutoTableLimit] = useState([]);
  const user_balance = useSelector((state) => state.UserBalance.user_balance);
  const [isExiting, setIsExiting] = useState(false);
  const modalRef = useRef();
  const [selectedAmount, setSelectedAmount] = useState(null); // Store selected amount
  const [gameConfirmMessage, setGameConfirmMessage] = useState("");
  const [newTableId, setNewTableId] = useState(null);
  const [currentTableId, setCurrentTableId] = useState(null);
  const [gameResponse, setGameResponse] = useState([]);
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);

  const handleClick = (index, autoTableLimit) => {    
    setActiveIndex(index);
    setSelectedAmount(autoTableLimit); // Store selected amount
    if (autoTableLimit >= user_balance.coin) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const fetchAutoTableLimit = () => {
    let reqData = {
      gameCode: gameCode,
      userLevel: userLevel
    };
    makeAjax(url.CARD_GAME_API.getAutoTableLimits, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return;
        }
        showMessage(response);
        return;
      }
      if (Array.isArray(response.data)) {
        setAutoTableLimit(response.data);
      }
    });
  };

  useEffect(() => {
    fetchAutoTableLimit();
  }, [gameCode]);


  async function getRandomTableRooms(gameCode, gameId, selectedAmount) {
    let reqData = {
      gameCode: gameCode,
      gameId: gameId,
      selectedAmount: selectedAmount,
    };
  
    try {
      const response = await makeAjax(url.CARD_GAME_API.getRandomTableRooms, reqData);      
      if (response.status !== respStatus["SUCCESS"]) {
        if (response.code !== "validation_error") {
          showMessage(response);
          return null;
        }
        showMessage(response);
        return null;
      }
  
      if (response.data['rooms'] && response.data['rooms'].length > 0) {
        return response.data['rooms'][0]['table_id'];
      } else {
        showMessage("No valid tableId found.");
        return null;
      }
    } catch (error) {
      showMessage("An unexpected error occurred. Please try again later.");
      return null;
    }
  }

  const handlePlayClick = async () => {
    if (activeIndex === null) {
      setIsPopupOpen(true);
    } else {
      const tableId = await getRandomTableRooms(gameCode, gameId, selectedAmount);
      if (tableId) {
        setNewTableId(tableId);
        let reqData = {
          gameCode: gameCode,
          tableId: tableId,
        };
        console.log('reqData',reqData);
        try {
          // Make the AJAX request to get the game link
          makeAjax(url.CARD_GAME_API.getGameLink, reqData).then((response) => {
            setGameResponse(response);
            if (response.status !== respStatus["SUCCESS"]) {
              if (response.status == "ERROR") {
                showMessage(props.t(response.code));
                return;
              }
              showMessage(response);
              return;
            } 
            
            gotoGame(response, gameCode, tableId);
          });
        } catch (error) {
          showMessage("An unexpected error occurred. Please try again later.");
        }
      } else {
        console.log('No valid tableId found.');
      }
    }
  };  

  let openedTabs = {};

  function gotoGame(response, gameCode, tableId) {
    console.log('response',response);
    console.log('gameCode',gameCode);
    console.log('tableId',tableId);
    console.log('selectedAmount',selectedAmount);

    const token = response.data.token;
    const operatorid = response.data.operatorid;
    const username = response.data.username;
    const one_time_token = response.data.one_time_token;

    let url = response.data.link;
    // let url = 'http://127.0.0.1:8004';
    const gameUrl = "/api/cardGame";
    let query_param = {
      gametypex: gameCode.toUpperCase(),
      tableid: tableId,
      token: token,
      operatorid: operatorid,
      username: username,
      one_time_token: one_time_token,
      randomlimit:selectedAmount
    };

    let param_string = Object.keys(query_param)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(query_param[key])
        );
      })
      .join("&");
    url = url + gameUrl + "?" + param_string;

    let tabName = openedTabs[gameCode] || `gameTab-${gameCode}`;

    const target = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? "_self"
      : tabName;

    const newTab = window.open(url, target);
  }

  const handleinfoClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleModalClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      setIsExiting(false);
      onClose();
    }, 300);
  };
  return (
    <div>
      <div className="modal_overlay" onClick={handleModalClose}></div>
      <div className="pokerdealer-popup">
        <div className={`modal ${!isExiting ? "popupani" : ""} `} ref={modalRef}>
          <div className="modal_close">
            <img src={CloseIcon} alt="close" onClick={handleModalClose} />
          </div>

          <div className="dealer-popup">
            <div className="poker-deler-img">
              <p>
                {gameListImages[gameId] && (
                  <img src={gameListImages[gameId]} alt={gameId} />
                )}
                {selectedGameName}
              </p>
            </div>
            <div className="popup-heading">
              <h5>Auto Table</h5>
            </div>

            <div className="auto-table">
              <div className="game-amount">
                {Array.isArray(autoTableLimit) &&
                  autoTableLimit.map((amount, index) => (
                    <div
                      key={index}
                      className={`amount ${index === activeIndex ? "active" : ""
                        } ${amount > user_balance.coin ? "disabled" : ""}`}
                      onClick={() => handleClick(index, amount)}
                      onMouseEnter={() =>
                        amount > user_balance.coin && setShowTooltip(true)
                      }
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      {amount}
                      {showTooltip && amount > user_balance.coin && (
                        <div className="tooltip">
                          You don't have enough Balance to play
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="poker-ply-btn">
                <button className="play-btn" onClick={handlePlayClick}>
                  PLAY
                </button>
              </div>
            </div>
            {isPopupOpen && <InformationPopup onClose={handleinfoClosePopup} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoTable;
