export function formatPrice(price) {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(\.\d{2})\d+/, '$1');
}


export const formatPricewithdecimal = (amount) => {
  if (!amount) {
    // If amount is undefined, null, or falsy, return '0' or an appropriate fallback value
    return '0';
  }

  // Parse the amount to a float after removing commas
  const num = parseFloat(amount.toString().replace(/,/g, ''));

  // Check if the number is an integer
  if (Number.isInteger(num)) {
    // Format without decimals if it's an integer
    return num.toLocaleString("en-US");
  } else {
    // Format with two decimal places if it's not an integer
    return num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};

