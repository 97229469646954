import React, { useState } from "react";
import CloseIcon from "../../../assets/images/modal/close.svg";
import pokerDealerImg from "../../../assets/images/GameList/poker-dealer.svg";

const InformationPopup = (props) => {
  const { onClose } = props;

  return (
    <>
      <div className="modal_overlay" onClick={onClose}></div>
      <div className="info-modal">
        <div className="modal">
          <div className="modal_close">
            <img src={CloseIcon} alt="close" onClick={onClose} />
          </div>
          <div className="information">
            <div className="heading">
              <h5>Information</h5>
            </div>
            <div className="info-line"></div>
            <div className="game-info">
              <p>Please Select a minimum limit</p>
            </div>
          </div>
        </div>
      </div> 
    </>
  );
};

export default InformationPopup;
