import React, { useState, useEffect, useRef } from "react";
import { VscChevronDown } from "react-icons/vsc";
import { VscChevronUp } from "react-icons/vsc";
import refreshBtn from "../../../assets/images/icons/refresh_btn.png";
import { withTranslation } from "react-i18next";
import { FaArrowUpLong } from "react-icons/fa6";
import {
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../../helpers/global_helper";
import { formatPricewithdecimal } from "../../NumberFormat";
import ResultPopup from "./ResultPopup";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PlayerPopup from "./PlayerPopup";
import TableIconDisplay from "./TableIconDisplay ";
import CloseIcon from "../../../assets/images/modal/close_iconpopup.svg";
import Jackpot from "./Jackpot";
import Modal from "../../Modal/Modal";
const TableDataInfo = (props) => {
  const [loading, setLoading] = useState(false);
  // const device = useSelector((state) => state.CommonConfig.device_type);
  const [isPagescroll, setPagescroll] = useState(false); // Start with the button hidden
  const scrollRef = useRef(null);

  // Function to check if the element has been scrolled more than 30px
  const checkScrollPosition = () => {
    if (scrollRef.current && scrollRef.current.scrollTop > 30) {
      setPagescroll(true);
    } else {
      setPagescroll(false);
    }
  };

  // Scroll back to the top of the element
  const BacktoTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  };

  useEffect(() => {
    const element = scrollRef.current;
    if (element) {
      // Add the scroll event listener
      element.addEventListener("scroll", checkScrollPosition);
    }

    // Clean up the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, []);

  const { onClose } = props;
  // props for device Mobile and Desktop
  const device = useSelector((state) => state.CommonConfig.device_type);
  const location = useLocation();
  let selectedGameName, gameCode, gameListImages, gameId;

  if (device === "Mobile") {
    const locationProps = location.state || {};
    ({ selectedGameName, gameCode, gameListImages, gameId } = locationProps);
    // console.log('=>Device: Mobile');
    // console.log('=>Location State:', location.state);
  } else {
    ({ selectedGameName, gameCode, gameListImages, gameId } = props);

    // console.log('=>Device: Desktop');
    // console.log('=>Props:', props);
  }
  const [tableData, setTableData] = useState([]);
  const [tableDataDiff, setTableDataDiff] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [specialtabData, setSpecialTabData] = useState([]);
  const [gameName, setGameName] = useState(gameId || "Select Game");
  const [roomName, setRoomName] = useState("All");
  const [isItems, setIsitems] = useState(false);
  const [hideFull, setHideFull] = useState(false);
  const [hideEmpty, setHideEmpty] = useState(false);
  const [tangkasHideFull, setTangkasHideFull] = useState(false);
  const [originalTableData, setOriginalTableData] = useState([]);
  const [resultPoupOpen, setResultPopupOpen] = useState(false);
  const [playerPopupOpen, setPlayerPopupOpen] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [isTrue, setIsTrue] = useState(true);
  const [roomFullPopup, setRoomFullPopup] = useState(false);
  const [showRoomPopup, setShowRoomPopup] = useState(false);
  const [gameConfirmMessage, setGameConfirmMessage] = useState("");
  const [newTableId, setNewTableId] = useState(null);
  const [currentTableId, setCurrentTableId] = useState(null);
  const [gameResponse, setGameResponse] = useState([]);

  const handlePopupOpen = (tableId) => {
    setSelectedTableId(tableId);
    setResultPopupOpen(true);
  };

  const handleClose = () => {
    setResultPopupOpen(false);
  };

  const handlePlayerPopupOpen = (tableId) => {
    setSelectedTableId(tableId);
    setPlayerPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPlayerPopupOpen(false);
  };

  const columnMappings = {
    // Texas Poker
    101: ["tablename", "tableminmax", "tableblind", "tableplayer", "Jackpot"],
    // Poker Dealer
    102: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Game Ceme
    103: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    //  Ceme Fighter
    104: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Domino QQ
    105: ["tablename", "tableminmax", "tablestakes", "tableplayer", "Jackpot"],
    // Black Jack
    106: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Big Two
    107: ["tablename", "tablemin", "tablestakes", "tableplayer"],
    // Super 10
    109: ["tablename", "tableminmax", "tableblind", "tableplayer"],
    // Omaha Poker
    110: ["tablename", "tableminmax", "tableblind", "tableplayer"],
    // Three Kings
    111: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Joker Dealer
    112: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Adu-Q
    113: ["tablename", "tablemin", "tablestakes", "tableplayer"],
    // Bull Bull Dice
    114: ["tablename", "tableminmax", "tablestakes", "tableplayer"],
    // Baccarat
    115: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Bandar QQ
    116: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Gaple
    117: ["tablename", "tablemin", "tablestakes", "tableplayer"],
    // Dadu Poker
    118: ["tablename", "tablemin", "tablestakes", "tableplayer"],
    // Rokak Uruk
    119: [
      "tablename",
      "tablemin",
      "tablestakes",
      "tableplayer",
      "tablemindealer",
    ],
    // Jisong
    120: ["tablename", "tableminmax", "tablestakes", "tableplayer"],
    // Texas Poker Classic
    151: ["tablename", "tableminmax", "tableblind", "tableplayer", "Jackpot"],
  };
  const specialColumnsMapping = {
    // Bola Tangkas
    108: ["No.", "user", "Royal", "5K", "STRF", "4K", "tablehistory"],
    // Tangkas Classic
    158: ["No.", "user", "Royal", "5K", "STRF", "4K", "tablehistory"],
  };
  // Translate column names
  const translateColumnNames = (columns, translate) => {
    return columns.map((column) => translate(column));
  };

  const columns = translateColumnNames(columnMappings[gameId] || [], props.t);
  const specialColumns = translateColumnNames(
    specialColumnsMapping[gameId] || [],
    props.t
  );
  // console.log("#columns", columns);
  // console.log("#tableDataDiff", tableDataDiff);
  // console.log("#specialtabData", specialtabData);
  // console.log("#selectedGameName :", selectedGameName, "Id :",gameId);
  const roomNameMapping = {
    All: "all",
    [props.t("smallest")]: 0,
    [props.t("small")]: 1,
    [props.t("medium")]: 2,
    [props.t("large")]: 3,
    [props.t("vip")]: 4,
  };

  console.log({columns})
  console.log({specialColumns})

  // API for fetching room Tab data
  const fetchTabData = () => {
    setLoading(true);
    let reqData = {
      gameCode: gameCode,
      gameId: gameId,
      subgame_togel: 3,
    };
    makeAjax(url.CARD_GAME_API.subGameRoomList, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      const roomNames = response.data.ind
        ? Object.values(response.data.ind).map((roomName) => {
            // Apply translation based on the roomName value
            switch (roomName.toLowerCase()) {
              case "smallest":
                return props.t("smallest");
              case "small":
                return props.t("small");
              case "medium":
                return props.t("medium");
              case "large":
                return props.t("large");
              case "vip":
                return props.t("vip");
              default:
                return roomName;
            }
          })
        : [];

      setTabData(roomNames);
      // Special room names for Bola Tangkas or Tangkas Classic
      const specialRoomNames = response.data.dataroom
        ? Object.values(response.data.dataroom).sort((a, b) => a.coin - b.coin)
        : [];
      setSpecialTabData(specialRoomNames);
    });
    setLoading(false);

  };
  useEffect(() => {
    applyFilters();
  }, [hideFull, hideEmpty, tangkasHideFull, originalTableData]);

  const applyFilters = () => {
    let filteredData = originalTableData;
    if (hideFull) {
      filteredData = filteredData.filter(
        (item) => item.total_player !== item.max_player
      );
    }
    if (tangkasHideFull) {
      filteredData = filteredData.filter((item) => item.nickname == null);
    }
    if (hideEmpty) {
      filteredData = filteredData.filter((item) => item.total_player !== 0);
    }
    setTableData(filteredData);
  };
  useEffect(() => {
    fetchTabData();
  }, []);

  // API for fetching table data
  const fetchTableData = (roomName) => {
    setLoading(true);
    let reqData = {
      gameCode: gameCode,
      roomName:
        roomNameMapping[roomName] !== undefined
          ? roomNameMapping[roomName]
          : roomName,
    };
    makeAjax(url.CARD_GAME_API.chooseRoom, reqData).then((response) => {
      if (response.status !== respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      const data = response.data.datatable || [];
      setTableData(data);
      setTableDataDiff(data);
      setOriginalTableData(data);
    });
    setLoading(false);
  };

  const sortTableData = (data, key, direction) => {
    return [...data].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];
      if (
        key === "jackpot" ||
        key === "min_buyin" ||
        key === "sblind" ||
        key === "total_player" ||
        key === "max_buyin" ||
        key === "bonus_royal" ||
        key === "bonus_5k" ||
        key === "bonus_str" ||
        key === "bonus_4k" ||
        key === "min_dealer" ||
        key === "stake" ||
        key === "prize_history" ||
        key === "nickname"
      ) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      } else if (key === "name") {
        aValue = aValue ? aValue.toString().toLowerCase() : "";
        bValue = bValue ? bValue.toString().toLowerCase() : "";

        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      }
    });
  };

  const handleFilter = (column) => {
    const newDirection = sortConfig.direction === "asc" ? "desc" : "asc";
    if (column === props.t("tablename")) {
      setSortConfig({ key: "name", direction: newDirection });
    } else if (column === "Jackpot") {
      setSortConfig({ key: "jackpot", direction: newDirection });
    } else if (column === props.t("tablestakes")) {
      // setSortConfig({ key: "stake", direction: newDirection });
      const key = gameId === 107 ? "stake" : "sblind";
      setSortConfig({ key, direction: newDirection });
    } else if (column === props.t("tableblind")) {
      const key = gameId === 101 || 110 ? "sblind" : "stake";
      setSortConfig({ key, direction: newDirection });
    } else if (column === props.t("tableplayer")) {
      setSortConfig({ key: "total_player", direction: newDirection });
    } else if (column === props.t("tableminmax")) {
      setSortConfig({ key: "max_buyin", direction: newDirection });
    } else if (column === props.t("tablemin")) {
      setSortConfig({ key: "min_buyin", direction: newDirection });
    } else if (column === props.t("tablemindealer")) {
      setSortConfig({ key: "min_dealer", direction: newDirection });
    }
    setIsTrue(!isTrue);
  };

  const handleFilterSpecial = (column) => {
    console.log(column);
    const newDirection = sortConfig.direction === "asc" ? "desc" : "asc";
    if (column === "Royal") {
      setSortConfig({ key: "bonus_royal", direction: newDirection });
    } else if (column === "5K") {
      setSortConfig({ key: "bonus_5k", direction: newDirection });
    } else if (column === "STRF") {
      setSortConfig({ key: "bonus_str", direction: newDirection });
    } else if (column === "4K") {
      setSortConfig({ key: "bonus_4k", direction: newDirection });
    }else if (column === "History") {
      setSortConfig({ key: "prize_history", direction: newDirection });
    }else if (column === "User") {
      setSortConfig({ key: "nickname", direction: newDirection });
    }
    setIsTrue(!isTrue);
  };

  const handleFullRoom = () => {
    setRoomFullPopup(true);
  };

  const sortedTableData = sortTableData(
    tableData,
    sortConfig.key,
    sortConfig.direction
  );

  console.log({tableData})
  useEffect(() => {
    fetchTableData(roomName);
  }, [gameCode, roomName]);

  const handleRefreshClick = () => {
    setIsitems(true);
    fetchTableData(roomName);
    setTimeout(() => {
      setIsitems(false);
    }, 2000);
  };

  const launchRoom = async (gameCode, tableId) => {
    setLoading(true);

    let reqData = {
      gameCode: gameCode,
      tableId: tableId,
    };

    try {
      // Make the AJAX request to get the game link
      makeAjax(url.CARD_GAME_API.getGameLink, reqData).then((response) => {
        // if (response.data.error != '') {
        //   showMessage(response.data.error);
        //   return;
        // }

        setGameResponse(response);
        if (response.status !== respStatus["SUCCESS"]) {
          if (response.status == "ERROR") {
            showMessage(props.t(response.code));
            return;
          }
          showMessage(response);
          return;
        } else if (response.code == "game_success") {
          let message = props
            .t(response.data.hold_table_text)
            .replace("{value}", response.data.hold_table_value);
          setGameConfirmMessage(message);
          setShowRoomPopup(true);
          setNewTableId(tableId);
          setCurrentTableId(response.data.hold_table_id);
          return;
        } else {
          gotoGame(response, gameCode, tableId);
        }
      });
    } catch (error) {
      showMessage("An unexpected error occurred. Please try again later.");
    }
    setLoading(false);

  };

  let openedTabs = {};

  function gotoGame(response, gameCode, tableId) {
    const token = response.data.token;
    const operatorid = response.data.operatorid;
    const username = response.data.username;
    const one_time_token = response.data.one_time_token;

    let url = response.data.link;
    // let url = 'http://127.0.0.1:8004';
    const gameUrl = "/api/cardGame";
    let query_param = {
      gametypex: gameCode.toUpperCase(),
      tableid: tableId,
      token: token,
      operatorid: operatorid,
      username: username,
      one_time_token: one_time_token,
    };

    let param_string = Object.keys(query_param)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + encodeURIComponent(query_param[key])
        );
      })
      .join("&");
    url = url + gameUrl + "?" + param_string;

    let tabName = openedTabs[gameCode] || `gameTab-${gameCode}`;

    const target = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? "_self"
      : tabName;

    const newTab = window.open(url, target);
    if (newTab) {
      openedTabs[gameCode] = tabName;
      startRepeatedFetch(3);
      checkIfTabClosed(newTab, () => {
        console.log("Perform actions after tab is closed");
      });
    } else {
      console.error("Failed to open the tab. Make sure pop-ups are allowed.");
    }
  }

  function checkIfTabClosed(newTab, callback) {
    const checkInterval = setInterval(() => {
      if (newTab.closed) {
        clearInterval(checkInterval);
        callback();
        startRepeatedFetch(2);
      }
    });
  }

  const startRepeatedFetch = (repeatCount) => {
    let count = 0;
    const interval = setInterval(() => {
      if (count >= repeatCount) {
        clearInterval(interval);
        return;
      }
      setIsitems(true);
      fetchTableData(roomName);
      setTimeout(() => {
        setIsitems(false);
      }, 2000);
      count++;
    }, 2000);
    return () => clearInterval(interval);
  };

  const handleButtonClick = (room) => {
    if (room == "current") {
      gotoGame(gameResponse, gameCode, currentTableId);
    } else {
      gotoGame(gameResponse, gameCode, newTableId);
    }
    setShowRoomPopup(false);
  };
  const renderTableRows = () => {
    if (sortedTableData.length === 0) {
      return (
        <tr>
          <td colSpan={columns.length + 1} style={{ textAlign: "center" }}>
            {props.t("data_not_found")}
          </td>
        </tr>
      );
    }
    return sortedTableData.map((item, index) => (
      <tr key={index}>
        {columns.map((column, colIndex) => (
          <td
            key={colIndex}
            className={
              column === props.t("tableplayer") ? "Player_blue_text" : ""
            }
          >
            {
              // Texas Poker
              gameId === 101 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 101 && column === props.t("tableminmax") ? (
                <>
                  {formatPricewithdecimal(item.min_buyin)} /{" "}
                  {formatPricewithdecimal(item.max_buyin)}
                </>
              ) : gameId === 101 && column === props.t("tableblind") ? (
                <>
                  {formatPricewithdecimal(item.sblind)} /{" "}
                  {formatPricewithdecimal(item.bblind)}
                </>
              ) : gameId === 101 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 101 && column === "Jackpot" ? (
                formatPricewithdecimal(item.jackpot)
              ) : //  Poker Dealer
              gameId === 102 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 102 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 102 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 102 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 102 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Game Ceme
              gameId === 103 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 103 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 103 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 103 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 103 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Ceme Fighter
              gameId === 104 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 104 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 104 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 104 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 104 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Domino QQ
              gameId === 105 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 105 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 105 && column === props.t("tablestakes") ? (
                // `${formatPricewithdecimal(item.sblind)} / ${formatPricewithdecimal(item.bblind)}`
                `${formatPricewithdecimal(item.sblind)}`
              ) : gameId === 105 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 105 && column === "Jackpot" ? (
                formatPricewithdecimal(item.jackpot)
              ) : //  Black Jack
              gameId === 106 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 106 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 106 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 106 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 106 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Big Two
              gameId === 107 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 107 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 107 && column === props.t("tablestakes") ? (
                formatPricewithdecimal(item.stake)
              ) : gameId === 107 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Super 10
              gameId === 109 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 109 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 109 && column === props.t("tableblind") ? (
                formatPricewithdecimal(item.stake)
              ) : gameId === 109 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Omaha Poker
              gameId === 110 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 110 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 110 && column === props.t("tableblind") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 110 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Three Kings
              gameId === 111 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 111 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 111 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 111 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 111 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Joker Dealer
              gameId === 112 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 112 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 112 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 112 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 112 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Adu-Q
              gameId === 113 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 113 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 113 && column === props.t("tablestakes") ? (
                // `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                `${formatPricewithdecimal(item.sblind)}`
              ) : gameId === 113 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Bull Bull Dice
              gameId === 114 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 114 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 114 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )} / ${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 114 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Baccarat
              gameId === 115 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 115 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 115 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 115 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 115 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Bandar QQ
              gameId === 116 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 116 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 116 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 116 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 116 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Gaple
              gameId === 117 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 117 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 117 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 117 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Dadu Poker
              gameId === 118 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 118 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 118 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 118 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Rokak Uruk
              gameId === 119 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 119 && column === props.t("tablemin") ? (
                formatPricewithdecimal(item.min_buyin)
              ) : gameId === 119 && column === props.t("tablestakes") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )}/${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 119 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : gameId === 119 && column === props.t("tablemindealer") ? (
                formatPricewithdecimal(item.min_dealer)
              ) : //  Jisong
              gameId === 120 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 120 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 120 && column === props.t("tablestakes") ? (
                // `${formatPricewithdecimal(item.sblind)}/${formatPricewithdecimal(item.bblind)}`
                `${formatPricewithdecimal(item.sblind * 1.5)}`
              ) : gameId === 120 && column === props.t("tableplayer") ? (
                // `${item.total_player} / ${item.max_player}`
                <span onClick={() => handlePlayerPopupOpen(item.table_id)}>
                  {`${item.total_player} / ${item.max_player}`}
                </span>
              ) : //  Texas Poker Classic
              gameId === 151 && column === props.t("tablename") ? (
                <>
                  {item.name}
                  <TableIconDisplay type={item.type} />
                </>
              ) : gameId === 151 && column === props.t("tableminmax") ? (
                `${formatPricewithdecimal(
                  item.min_buyin
                )} / ${formatPricewithdecimal(item.max_buyin)}`
              ) : gameId === 151 && column === props.t("tableblind") ? (
                `${formatPricewithdecimal(
                  item.sblind
                )} / ${formatPricewithdecimal(item.bblind)}`
              ) : gameId === 151 && column === props.t("tableplayer") ? (
                `${item.total_player} / ${item.max_player}`
              ) : gameId === 151 && column === "Jackpot" ? (
                formatPricewithdecimal(item.jackpot)
              ) : (
                ""
              )
            }
          </td>
        ))}
        <td>
          {item.total_player === item.max_player ? (
            <button
              className="btn btn_red"
              onClick={() => launchRoom(gameCode, item.table_id)}
            >
              {props.t("tablefull")}
            </button>
          ) : (
            <button
              className="btn btn_yellow"
              onClick={() => launchRoom(gameCode, item.table_id)}
            >
              {props.t("tableplay")}
            </button>
          )}
        </td>
      </tr>
    ));
  };

  const renderSpecialTableRows = () => {
    const { key, direction } = sortConfig;
    // Dynamically sort table data based on the current sort configuration
    const sortedTableData = [...tableData].sort((a, b) => {
      if (!key) return 0; // If no key is set, don't sort
  
      const valA = a[key] ?? "";
      const valB = b[key] ?? "";
  
      if (typeof valA === "number" && typeof valB === "number") {
        return direction === "asc" ? valA - valB : valB - valA;
      }
  
      if (typeof valA === "string" && typeof valB === "string") {
        return direction === "asc"
          ? valA.localeCompare(valB)
          : valB.localeCompare(valA);
      }
      return 0;
    });
    if (sortedTableData.length === 0) {
      return (
        <tr>
          <td
            colSpan={specialColumns.length + 1}
            style={{ textAlign: "center" }}
          >
            {props.t("data_not_found")}
          </td>
        </tr>
      );
    }

    return sortedTableData.map((item, index) => (
      <tr key={index}>
        {specialColumns.map((column, colIndex) => (
          <td key={colIndex} className="">
            {
              // Bola Tangkas
              (gameId === 108 || gameId === 158) && column === "No." ? (
                // index + 1
                (item.number)
              ) : gameId === 108 && column === props.t("user") ? (
                (item.nickname) ? item.nickname : item.xuser_name
              ) : gameId === 108 && column === "Royal" ? (
                formatPricewithdecimal(item.bonus_royal)
              ) : gameId === 108 && column === "5K" ? (
                formatPricewithdecimal(item.bonus_5k)
              ) : gameId === 108 && column === "STRF" ? (
                formatPricewithdecimal(item.bonus_str)
              ) : gameId === 108 && column === "4K" ? (
                formatPricewithdecimal(item.bonus_4k)
              ) : gameId === 108 && column === props.t("tablehistory") ? (
                <span
                  onClick={() => handlePopupOpen(item.table_id)}
                  className="Player_text"
                >
                  {item.prize_history.split("  ").map((word, wordIndex) => {
                    const listPrize = [
                      "str",
                      "mstr",
                      "strf",
                      "mstrf",
                      "jpstr",
                      "5k",
                      "m5k",
                      "5kf",
                      "m5kf",
                      "jp5k",
                      "royal",
                      "mroyal",
                      "royalf",
                      "mroyalf",
                      "jproyal",
                      "jprf",
                    ];
                    const isPrizeIncluded = listPrize.includes(
                      word.toLowerCase()
                    );
                    return (
                      <span
                        key={wordIndex}
                        id={item.table_id}
                        className={
                          isPrizeIncluded ? "Player_blue_text" : "Player_text"
                        }
                        onClick={() => handlePopupOpen(item.table_id)}
                      >
                        {word}{" "}
                      </span>
                    );
                  })}
                </span>
              ) : // Tangkas Classic
              gameId === 158 && column === "No." ? (
                index + 1
              ) : gameId === 158 && column === props.t("user") ? (
                item.nickname
              ) : gameId === 158 && column === "Royal" ? (
                formatPricewithdecimal(item.bonus_royal)
              ) : gameId === 158 && column === "5K" ? (
                formatPricewithdecimal(item.bonus_5k)
              ) : gameId === 158 && column === "STRF" ? (
                formatPricewithdecimal(item.bonus_str)
              ) : gameId === 158 && column === "4K" ? (
                formatPricewithdecimal(item.bonus_4k)
              ) : gameId === 158 && column === props.t("tablehistory") ? (
                <span
                  onClick={() => handlePopupOpen(item.table_id)}
                  className="Player_text"
                >
                  {item.prize_history.split("  ").map((word, wordIndex) => {
                    const listPrize = [
                      "str",
                      "mstr",
                      "strf",
                      "mstrf",
                      "jpstr",
                      "5k",
                      "m5k",
                      "5kf",
                      "m5kf",
                      "jp5k",
                      "royal",
                      "mroyal",
                      "royalf",
                      "mroyalf",
                      "jproyal",
                      "jprf",
                    ];
                    // Check if the current word is in the listPrize array
                    const isPrizeIncluded = listPrize.includes(
                      word.toLowerCase()
                    );
                    return (
                      <span
                        key={wordIndex}
                        id={item.table_id}
                        className={
                          isPrizeIncluded ? "Player_blue_text" : "Player_text"
                        }
                        onClick={() => handlePopupOpen(item.table_id)}
                      >
                        {word}{" "}
                      </span>
                    );
                  })}
                </span>
              ) : (
                " "
              )
            }
          </td>
        ))}
        <td>
          {item.nickname !== null || item.xuser_name !== null ? (
            <button className="btn btn_red" onClick={handleFullRoom}>
              {props.t("tablefull")}
            </button>
          ) : (
            <button
              className="btn btn_yellow"
              onClick={() => launchRoom(gameCode, item.table_id)}
            >
              {props.t("tableplay")}
            </button>
          )}
        </td>
      </tr>
    ));
  };

  return (
    <>
          {!loading &&

      <div className="inner_content">
        <div className="table content_wrapper">

          {device === "Mobile" && (
            <Jackpot selectedGameName={selectedGameName} gameCode={gameCode} />
          )}
          
          <div className="middleTableBar" cellSpacing="0">
            <p>
              {gameListImages[gameId] && (
                <img src={gameListImages[gameId]} alt={gameId} />
              )}
              {selectedGameName}
            </p>
            {device === "Desktop" && (
              <Jackpot
                selectedGameName={selectedGameName}
                gameCode={gameCode}
              />
            )}
            {gameId !== 108 && gameId !== 158 && (
              <div className="middleTableRow_checklist">
                <div className="checklist_checkbox">
                  <input
                    type="checkbox"
                    checked={hideFull}
                    onChange={() => setHideFull(!hideFull)}
                  />
                  <span>{props.t("hide_full")}</span>
                </div>
                <div className="checklist_checkbox">
                  <input
                    type="checkbox"
                    checked={hideEmpty}
                    onChange={() => setHideEmpty(!hideEmpty)}
                  />
                  <span>{props.t("hide_empty")}</span>
                </div>
              </div>
            )}

            {(gameId === 108 || gameId === 158) && (
              <div className="middleTableRow_checklist">
                <div className="checklist_checkbox">
                  <input
                    type="checkbox"
                    checked={tangkasHideFull}
                    onChange={() => setTangkasHideFull(!tangkasHideFull)}
                  />
                  <span>{props.t("hide_full")}</span>
                </div>
              </div>
            )}
          </div>

          {gameId !== 108 && gameId !== 158 && (
            <div className="topTableBar">
              {tabData.length > 0 && (
                <>
                  <div
                    className={`topTableBarIcon ${
                      roomName === "All" ? "active" : ""
                    }`}
                    onClick={() => setRoomName("All")}
                  >
                    All
                  </div>
                  {tabData.map((room, index) => (
                    <div
                      key={index}
                      className={`topTableBarIcon ${
                        roomName === room ? "active" : ""
                      }`}
                      onClick={() => setRoomName(room)}
                    >
                      {room}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}

          {gameId === 108 || gameId === 158 ? (
            <div className="selectedGameTable">
              <div className="topTableBar">
                {specialtabData.length > 0 ? (
                  <>
                    <div
                      className={`topTableBarIcon ${
                        roomName === "All" ? "active" : ""
                      }`}
                      onClick={() => setRoomName("All")}
                    >
                      All
                    </div>
                    {specialtabData.map((room, index) => (
                      <div
                        key={index}
                        className={`topTableBarIcon ${
                          roomName === room.name ? "active" : ""
                        }`}
                        onClick={() => setRoomName(room.name)}
                      >
                        {room.name}
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className={`topTableBarIcon ${
                      roomName === "All" ? "active" : ""
                    }`}
                    onClick={() => setRoomName("All")}
                  >
                    All
                  </div>
                )}
              </div>
              <div className="table_container" ref={scrollRef}>
                <table className="dataTableMenuBar" border="0">
                  <thead>
                    <tr>
                      {specialColumns.map((column, index) => (
                        <th
                          key={index}
                          onClick={() => handleFilterSpecial(column)}
                        >
                          {column === "No." ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {/* {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )} */}
                            </div>
                          ) : column === props.t("user") ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "Royal" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "5K" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "STRF" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === "4K" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : column === props.t("tablehistory") ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              {column}
                              {isTrue ? (
                                <VscChevronDown size={20} />
                              ) : (
                                <VscChevronUp size={20} />
                              )}
                            </div>
                          ) : (
                            column
                          )}
                        </th>
                      ))}
                      <th onClick={handleRefreshClick}>
                        <img
                          src={refreshBtn}
                          alt=""
                          className={`coin_refresh ${
                            isItems ? "coinRotation" : ""
                          }`}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderSpecialTableRows()}</tbody>
                </table>
                {isPagescroll && (
                  <button className="scroll-to-top visible" onClick={BacktoTop}>
                    <FaArrowUpLong />
                  </button>
                )}


              </div>
            </div>
          ) : (
            <div className="table_container" >
              <table className="dataTableMenuBar " border="0">
                <thead>
                  <tr>
                    {columns.map((column, index) => (
                      <th key={index} onClick={() => handleFilter(column)}>
                        {column === props.t("tablename") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableminmax") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablemin") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablestakes") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tablemindealer") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableblind") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === props.t("tableplayer") ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : column === "Jackpot" ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            {column}
                            {isTrue ? (
                              <VscChevronDown size={20} />
                            ) : (
                              <VscChevronUp size={20} />
                            )}
                          </div>
                        ) : (
                          column
                        )}
                      </th>
                    ))}
                    <th onClick={handleRefreshClick}>
                      <img
                        src={refreshBtn}
                        alt=""
                        className={`coin_refresh ${
                          isItems ? "coinRotation" : ""
                        }`}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTableRows()}</tbody>
              </table>
        
            </div>
          )}
          
        </div>

        {resultPoupOpen && (
          <ResultPopup
            onClose={handleClose}
            tableid={selectedTableId}
            gameid={gameId}
          />
        )}
        {/* vip player popup */}
        {playerPopupOpen && (
          <PlayerPopup
            onClose={handleClosePopup}
            tableid={selectedTableId}
            gameid={gameId}
          />
        )}
        {roomFullPopup && (
          <>
            <div
              className="overlay-div"
              onClick={() => setRoomFullPopup(false)}
            ></div>
            <div className="modal_popup result_popup">
              <div className="modal_popup_header">
                <h2 className="heading_1">{props.t("message")}</h2>
                <button
                  className="modal_popup_close"
                  onClick={() => setRoomFullPopup(false)}
                >
                  <img src={CloseIcon} alt="Close" />
                </button>
              </div>
              <div className="result_container">
                <div className="table_content_container">
                  <div className="result_data_main">
                    <p>{props.t("max_room_is_reach")}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {showRoomPopup && (
          <div className="room_popup">
            <Modal
              modalHeader={true}
              modalTitle="Information"
              onCloseModal={() => setShowRoomPopup(false)}
              className="room_popup"
            >
              <div className="room_content">
                <p dangerouslySetInnerHTML={{ __html: gameConfirmMessage }}></p>
                <div className="btns">
                  <button
                    className="btn btn-disabled btn-current-room"
                    onClick={() => handleButtonClick("current")}
                  >
                    Current Room
                  </button>{" "}
                  &nbsp;
                  <button
                    className="btn btn-blue btn-new-room"
                    onClick={() => handleButtonClick("new")}
                  >
                    New Room
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    }
    </>
  );
};

export default withTranslation()(TableDataInfo);
