import HeaderRightMenu from './SubComponent/HeaderRightMenu';
import ProfileDetail from './SubComponent/HeaderLeftMenu/ProfileDetail';
import WebNavigation from './SubComponent/HeaderLeftMenu/WebNavigation';
import Logo from './SubComponent/HeaderLeftMenu/Logo';



const Header = () => {
    return (
        <>
            <div className="header">
                <div className='headerLeftSection'>
                    <ProfileDetail />
                </div>
                <div className='headerCenterSection'>
                    <Logo />
                </div>
                <div className='headerRightSection'>
                    <WebNavigation />
                    <HeaderRightMenu />
                </div>
            </div>
        </>
    )
}

export default Header



