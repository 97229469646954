import axios from "axios";
import * as url from "./url_helper";
let tokenPrefix = "Bearer ";
if (custom_loader_count === undefined) var custom_loader_count = 0;
export const respStatus = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  NOT_AUTHORISED: "You are not authorised.",
};
// Register Method
const base_url = window.location.origin;
const axiosApi = axios.create({
  baseURL:
    process.env.REACT_APP_ENABLE_LOCAL_API == 1
      ? process.env.REACT_APP_API_URL
      : base_url,
});
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
const makeAjax = (
  extension,
  data,
  micro_service = url.CARD_GANE_MS_EXT,
  header = {}
) => {
  startLoader(extension);
  let token = tokenPrefix + localStorage.getItem(url.TOKEN_KEY);

 
  axiosApi.defaults.headers.common["Authorization"] = token;
  let end_url =
    url.API_PREFIX + micro_service + url.APPLICATION_EXT + extension;
  if (process.env.REACT_APP_ENABLE_LOCAL_API == 2) {
    end_url = micro_service + url.APPLICATION_EXT + extension;
  }
  // console.log("lobby access ====>", process.env);
  if (!header['headers'])
    header['headers'] = {}
  header['headers'][url.LOBBY_KEY] = process.env.REACT_APP_LOBBY;
  header['headers'][url.LOBBY_MOB_PERM] = 'new_cd_lob_mob_status';
  header['headers'][url.LOBBY_WEB_PERM] = 'new_cd_lob_web_status';
  header['headers'][url.LOBBY_VERSION_KEY] = process.env.REACT_APP_LOBBY_VERSION;
  return axiosApi
    .post(end_url, data, header)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) {
        if (response.data.status == "ERROR") {
          if (response.data.code == "invalid_token_error" || response.data.code == "contact_to_customer_err") {
            localStorage.removeItem(url.TOKEN_KEY);
            window.location.href = base_url + "/sessionexpired";
          }
          else if (response.data.code == "inactive_partner" && response.data.code == "contact_to_customer_err") {
            showMessage(response);
            localStorage.removeItem(url.TOKEN_KEY);
            window.location.href = base_url + "/maintenance";
          }
          else if(response.data.code === "page_refresh") {
            window.location.reload()
          }
        }
        stopLoader(extension);
        return response.data;
      }
      stopLoader(extension);
      return { status: "ERROR", data: null };
    })
    .catch((err) => {
      stopLoader(extension);
      catchAxioExc(err);
      return { status: "ERROR", data: null, messages: generateCatchMsg(err) };
    });
};

const startLoader = (extension) => {
  if (!url.EXCLUDE_LOADER.includes(extension)) {
    let element = document.getElementById("ajax-loader");
    if (element) {
      if (custom_loader_count == 0) {
        element.classList.remove("hideMe");
      }
      custom_loader_count++;
      // console.log("startLoader", custom_loader_count);
    }
  }
};

const stopLoader = (extension) => {
  if (!url.EXCLUDE_LOADER.includes(extension)) {
    let element = document.getElementById("ajax-loader");
    if (element) {
      if (custom_loader_count != 0) custom_loader_count--;
      if (custom_loader_count == 0) {
        element.classList.add("hideMe");
      }
      // console.log("stopLoader", custom_loader_count);
    }
  }
};


const generateCatchMsg = (err) => {
  var message;
  if (err.response && err.response.status) {
    switch (err.response.status) {
      case 404:
        message = "Sorry! the API you are looking for could not be found";
        break;
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
    }
    return message;
  }
};
const catchAxioExc = (err) => {
  var message = generateCatchMsg(err);
  showMessage(message, "error");
};
const showMessage = (data, toastType, title) => {
  let message = data;
  if (data.messages) message = data.messages;
  if (!toastType) {
    toastType = data.status == "SUCCESS" ? "SUCCESS" : "error";
  }
  if (!title) title = toastType == "success" ? "Success" : camelCase(toastType);
  toastMessage(message, toastType, title);
  if(data.code === "you_ve_been_logged_out_successfully" || data.code === "invalid_token_error") {
    setTimeout(() => {
      window.close()
    }, 2000)
  }
};
const toastMessage = (message, type, heading = "Error") => {
  document.getElementById("commonToaster").style.display = "flex";
  document.getElementById("commonToasterHeading").innerHTML = heading;
  document.getElementById("commonToasterBody").innerHTML = message;
};
const camelCase = (message) => {
  return message.charAt(0).toUpperCase() + message.slice(1);
};
const formatDate = (date) => {
  let arr = date.split(/[- :]/);
  // let d = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
  let d = new Date(arr[0], arr[1] , arr[2], arr[3], arr[4], arr[5]);
  return appendZero(d.getDate()) + '-' + appendZero(d.getMonth()) + '-' + appendZero(d.getFullYear()) + ' ' + appendZero(d.getHours()) + ':' + appendZero(d.getMinutes());
};
const appendZero = (val) => {
  return (val < 10) ? '0' + val : val;
}

export { makeAjax, url, tokenPrefix, showMessage, camelCase, formatDate };