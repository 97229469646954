import React, { useEffect, useState } from "react";
import {
  formatDate,
  makeAjax,
  respStatus,
  showMessage,
  url,
} from "../../helpers/global_helper";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NotifiResultPopup from "./NotifiResultPopup";
// import NotifyIcon from "../../assets/images/icons/navigation/notification.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { getUserBalance } from "../../redux/actions";

import NotifyIcon from "../../assets/images/HeadIcon/notify.svg";
import { useSelector } from "react-redux";
const NotificationPage = (props) => {
  const dispatch = useDispatch();
  const { onClose } = props;
  const [isResultPopup, setIsResultPopup] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [notificationSubject, setNotificationSubject] = useState(null);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const device = useSelector(state => state.CommonConfig.device_type);
  const openNotificationResultPopup = (index, notificationId, notificationSubject) => {
    let t = [...items];
    t[index].isNew = 0;
    setItems([...t]);
    setNotificationId(notificationId);
    setNotificationSubject(notificationSubject);
    setIsResultPopup(true);
  };
  const closeNotificationResultPopup = () => {
    setIsResultPopup(false);
  };
  // api for Notification list
  const fetchNotificationData = () => {
    let reqData = {
      page: page,
    };
    makeAjax(url.PLAYER_API.listNotification, reqData, url.PLAYER_MS_EXT).then(
      (response) => {
        console.log("noti==", response.data.notifResults.data);

        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage("server error No data find", "error", "error");
            return;
          }
          showMessage("server error No data find", "error", "error");
          return;
        }
        const newData = response.data.notifResults.data;
        setItems((prevItems) => [...prevItems, ...newData]);
        setPage((prevPage) => prevPage + 1);
        setHasMore(newData.length > 0);
        dispatch(getUserBalance());
      }

    );
  };
  useEffect(() => {
    fetchNotificationData();
  }, []);

  return (
    <>
      <div className="inner_content">
        <div className="table content_wrapper">
          {
            device === "Mobile" || device === "Tablet" ? <><h2 className="heading_1"><img src={NotifyIcon} alt="" />Notification</h2><div className="header_bottom_border"></div></> : ""
          }



          <button className="modal_popup_close" onClick={onClose}>

          </button>

          <div className="table_container notify_container">
            <InfiniteScroll
              dataLength={items.length}
              next={() => fetchNotificationData()}
              pullDownToRefreshThreshold={50}
              hasMore={hasMore}
              //   loader={<h4 className="ladtext">Loading...</h4>}
              endMessage={<p>{props.t("no_more_items_to_load")}</p>}
              scrollThreshold={0.7}
              height={"60vh"}
              style={{
                overflow: "auto",
                height: "inherit",
                maxHeight: `${device === "Mobile" ? "calc(100dvh - 150px)" : "calc(100dvh - 110px)"}`,
                color: "#fff",
                fontSize: "12px",
              }}
            >
              <table className="dataTableMenuBar notify_table" border="0">
                <thead>
                  <tr>
                    <th> {props.t("no.")}</th>
                    <th> {props.t("subject")}</th>
                    <th> {props.t("date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.map((notification, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="notification_row_content">
                              <a
                                onClick={() =>
                                  openNotificationResultPopup(
                                    index,
                                    notification.id,
                                    notification.subject
                                  )
                                }
                              >
                                {notification.subject}
                              </a>
                              <div
                                style={{ color: "#fff", textDecoration: "none" }}
                              >
                                {notification.isNew ? "New" : ""}
                              </div>
                            </div>
                          </td>
                          <td style={{ width: device === "Mobile" ? 80 : "" }}>{formatDate(notification.date)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
          {/* table end  */}
        </div>
      </div>
      {isResultPopup && (
        <div className="notification_popup">
          <NotifiResultPopup
            onClose={closeNotificationResultPopup}
            notificationId={notificationId}
            notificationSubject={notificationSubject}
          />
        </div>
      )}
    </>
  );
};
export default withTranslation()(NotificationPage);
