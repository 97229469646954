import React, { useEffect, useState } from 'react';
import { Routes, Route } from "react-router-dom";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Profile from "../Header/SubComponent/HeaderLeftMenu/Profile";
import NotificationPage from '../NotificationPage/NotificationPage'; import Jackpot from '../Jackpot/Jackpot';
import CoinRewards from '../CoinRewards/CoinRewards';
import InformationMoDesign from '../MobileDesign/InformationMoDesign';
import TableDataInfo from '../Main/SubComponent/TableDataInfo';
import { makeAjax, respStatus, url } from '../../helpers/global_helper';
import NickName from '../NickName/NickName';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../../redux/actions';
import Promotion from '../PromotionPage/PromotionPage';
const NavigationComponent = () => {
  // const dispatch = useDispatch();
  // const [checkNickname, setNickname] = useState('')
  // const getNickName=async ()=>{
  //   const response = await makeAjax(url.PLAYER_API.getUser, {}, url.PLAYER_MS_EXT);
  //   const nickname= response.data.user.nickname
  //   setNickname(nickname)
  //   if (response.status === respStatus['SUCCESS']) {
  //     if (nickname === '') {
  //       // window.location.href = window.location.origin + "/nickname";
  //     }
  //   }
  // }


  //   useEffect(()=>{
  //     getNickName();
  //     dispatch(getUserProfile());
  //   },[])

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/notification" element={<NotificationPage />} />
        <Route path="/information" element={<InformationMoDesign />} />
        <Route path='/promotion' element={<Promotion />} />
        <Route path="/jackpot" element={<Jackpot />} />
        <Route path="/coinReward" element={<CoinRewards />} />
        <Route path="/gamedata" element={<TableDataInfo />} />
      </Routes>
      <Footer />
    </>

  );
}

export default NavigationComponent;
