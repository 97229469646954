import React, { useState, useEffect } from "react";
import {
  makeAjax,
  respStatus,
  url,
  showMessage,
} from "../../../helpers/global_helper";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../redux/user-profile/actions";
import CloseIcon from "../../../assets/images/modal/close_iconpopup.svg";
import Coin from "../../../assets/images/icons/coins.svg";
import img1 from "../../../assets/images/ProfilePic/image1.png";
import { withTranslation } from "react-i18next";
import { formatPricewithdecimal } from "../../NumberFormat";

const PlayerPopup = (props) => {
  const { onClose, tableid, gameid } = props;
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [avatarUrl, setAvatarUrl] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { user_profile } = useSelector((state) => ({
    user_profile: state.UserProfile,
  }));

  useEffect(() => {
    dispatch(getUserProfile());

    if (tableid) {
      const fetchSelectedTabData = async () => {
        const profilData = await makeAjax(url.PLAYER_API.getFtpUrl, {}, url.PLAYER_MS_EXT);
        setAvatarUrl(profilData.data)
        makeAjax(url.CARD_GAME_API.tableDetail, { gameid, tableid }).then(
          (response) => {
            setLoading(false);
            if (response.status !== respStatus["SUCCESS"]) {
              showMessage(response);
              return;
            }
            let data = response.data.data || [];
            setTableData(data);
          }
        );
      };
      fetchSelectedTabData();
      setProfileImageUrl(user_profile.myAvatarLink);
    }
  }, [tableid, gameid, setProfileImageUrl]);


  const getProfileImageUrl = (nickname) => {
    const imageArray = [];
    const firstChar = nickname ? nickname.charAt(0) : "";
    const imageUrl = `${avatarUrl}/avatar/${firstChar}/${nickname}.jpg`;
    imageArray.push(imageUrl);
    return imageArray;
  };
  return (
    <>
      <div className="overlay-div" onClick={onClose}></div>
      <div className="modal_popup result_popup player_popup">
        <div className="modal_popup_header">
          <h2 className="heading_1">{props.t("user")}</h2>
          <button className="modal_popup_close" onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </button>
        </div>
        <div className="player_container">
          {loading ? (
            <div className="pc_row_two">{props.t("loading")}</div> // Show loading while waiting for data
          ) : tableData && tableData.length > 0 ? (
            tableData.map((player, index) => (
              <div className="pc_row" key={index}>
                <div className="pc_column_left" key={index}>
                  <div className="pc_player_photo">
                    {
                      // Generate an array of image URLs
                      getProfileImageUrl(player.nickname).map((url, idx) => (
                        <img
                          key={idx}
                          src={url}
                          alt={player.nickname}
                          onError={(e) => {
                            e.target.src = img1;
                          }}
                        />
                      ))
                    }
                  </div>
                  <p>{player.nickname}</p>
                </div>
                <div className="pc_column_right">
                  <img src={Coin} alt="" />
                  <p>{formatPricewithdecimal(player.coin)}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="pc_row_two">{props.t("player_not_found")}</div>
          )}
        </div>
      </div>
    </>
  );
};
export default withTranslation()(PlayerPopup);
